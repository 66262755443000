import React, { useState } from 'react';
import { SignInFormInterface } from './interface';
import useStyles from './style';
import HeadForm from '../../../../components/HeadForm';
import TitleForm from '../../../../components/TitleForm';
import GoogleButton from '../../../../components/GoogleButton';
import ButtonSubmit from '../../../../components/ButtonSubmit';
import LinkContainer from '../../../../components/LinkContainer';
import CheckboxKeepConnected from '../CheckboxKeepConnected';
import { Link } from 'react-router-dom';
import Input from '../../../../components/Input';
import ReCAPTCHA from 'react-google-recaptcha';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import { getLanguage } from '../../../../../../utils/function/getLanguage';

const SignInForm = ({
  email,
  setEmail,
  password,
  setPassword,
  captcha,
  setCaptcha,
  connected,
  loading,
  handleChange,
  onSubmit
}: SignInFormInterface) => {
  const classes: any = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const [language, setLanguage] = useState(getLanguage());

  return (
    <div className={classes.root}>
      <div>
        <HeadForm
          title={translate('page.signIn.form.action.firstAccess')}
          textButton={translate('page.signIn.form.action.signUp')}
          routeButton="/sign-up"
        />

        <TitleForm title={translate('page.signIn.form.title')} />

        <GoogleButton>
          {translate('page.signIn.form.action.signInWithGoogle')}
        </GoogleButton>

        <form action="" onSubmit={onSubmit} className={classes.form}>
          <Input
            id="email"
            label={translate('page.signIn.form.email.label')}
            placeholder={translate('page.signIn.form.email.placeholder')}
            type="text"
            value={email}
            setValue={setEmail}
          />

          <Input
            id="senha"
            label={translate('page.signIn.form.password.label')}
            placeholder={translate('page.signIn.form.password.placeholder')}
            type="password"
            value={password}
            setValue={setPassword}
          />

          <ReCAPTCHA
            sitekey="6Lck3ToaAAAAAA-EZIPMqAA3nQ-PtyKhoa0K3oU5"
            onChange={() => setCaptcha(true)}
            onErrored={() => setCaptcha(false)}
            onExpired={() => setCaptcha(false)}
            className={classes.captcha}
            hl={language}
          />

          <LinkContainer>
            <CheckboxKeepConnected
              connected={connected}
              handleChange={handleChange}
            />

            <Link to="/forgot-password" className={classes.link}>
              {translate('page.signIn.form.action.forgot')}
            </Link>
          </LinkContainer>

          <ButtonSubmit disabled={loading || !captcha} loading={loading}>
            {translate('page.signIn.form.action.signIn')}
          </ButtonSubmit>
        </form>
      </div>
    </div>
  );
};

export default SignInForm;
