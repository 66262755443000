/* eslint-disable no-undef */
import AuthRequests from '../repositories/auth';

import {
  AUTH_SIGN_IN,
  AUTH_SIGN_UP,
  AUTH_GOOGLE_AUTH,
  AUTH_SIGN_OUT,
  AUTH_CHECK_TOKEN,
  USER_DETAIL
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import notify from '../../utils/notify';
import RequestErrorHelper from '../../utils/helper/RequestErrorHelper';
import analytics from '../../utils/function/analytics';
import mapping from '../../utils/function/mapping';

type SignInProps = {
  email: string;
  password: string;
  connected: boolean;
  type?: string;
};
type SignUpProps = {
  name: string;
  email: string;
  password: string;
  accept: boolean;
  type?: string;
};

export const signIn =
  ({ email, password, connected, type }: SignInProps, cb?: any) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());

    try {
      await analytics.logEvent('user', 'sign-in');

      const payload: any = await AuthRequests.signIn({
        email,
        password,
        type
      });

      const { token, user } = payload;

      dispatch({
        payload,
        type: AUTH_SIGN_IN
      });

      dispatch({
        payload: {
          _id: user._id || '',
          name: user.name || '',
          picture: user.picture || '',
          email: user.email || '',
          phone: user.phone || '',
          isSubscriber: user.isSubscriber || false,
          options: user.options || {},
          dictionary: user.dictionary || [],
          disabledRules: user.disabledRules || [],
          since: user.since || undefined,
          subscription: user.subscription || undefined,
          stripe_id: user.stripe_id || ''
        },
        type: USER_DETAIL
      });

      localStorage.setItem('token', token);

      await notify.welcome('Olá, ', user);

      await mapping.identify(user._id);

      await mapping.people.set({
        $name: user.name,
        $email: user.email
      });

      await mapping.track('Sign In');

      if (cb && cb.success) {
        cb.success(user, user?.firstAccess);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const signUp =
  ({ name, email, password, accept, type }: SignUpProps, cb?: any) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());

    try {
      await analytics.logEvent('user', 'sign-up');

      const payload: any = await AuthRequests.signUp({
        name,
        email,
        password,
        type
      });

      dispatch({
        payload,
        type: AUTH_SIGN_UP
      });

      if (cb && cb.success) {
        cb.success();
      }

      await notify.success(
        'Cadastro realizado com sucesso! Cheque sua caixa de e-mail antes de começarmos.'
      );

      await mapping.track('Sign Up');
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const googleAuth =
  ({ accessToken, type }: any, cb: any) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());

    try {
      await analytics.logEvent('user', 'google-sign-in');

      const data: any = { access_token: accessToken, type };

      // @ts-ignore
      const rewardful: any = window.Rewardful;

      // @ts-ignore
      if (rewardful && rewardful.referral && rewardful._cookie) {
        data.referral = {
          client_reference_id: rewardful.referral,
          created_at: rewardful?._cookie?.created_at
        };
      }

      const payload: any = await AuthRequests.googleAuth(data);

      const { token, user } = payload;

      dispatch({
        payload,
        type: AUTH_GOOGLE_AUTH
      });

      dispatch({
        payload: {
          _id: user._id || '',
          name: user.name || '',
          picture: user.picture || '',
          email: user.email || '',
          phone: user.phone || '',
          isSubscriber: user.isSubscriber || false,
          options: user.options || {},
          dictionary: user.dictionary || [],
          disabledRules: user.disabledRules || [],
          since: user.since || undefined,
          subscription: user.subscription || undefined,
          stripe_id: user.stripe_id || ''
        },
        type: USER_DETAIL
      });

      localStorage.setItem('token', token);

      if (new Date().getTime() - new Date(user.since).getTime() < 60000) {
        if (rewardful) {
          // @ts-ignore
          window.rewardful('convert', { email: user.email });
        }
      }

      await mapping.identify(user._id);

      await mapping.people.set({
        $name: user.name,
        $email: user.email
      });

      await mapping.track('Sign In with Google');

      if (cb && cb.success) {
        cb.success(user, user?.firstAccess);
      }

      await notify.welcome('Olá, ', user);
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const mailConfirmation =
  ({ code }: any, cb?: any) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());

    try {
      await analytics.logEvent('user', 'mail-check');

      const payload: any = await AuthRequests.mailConfirmation(code);

      if (cb && cb.success) {
        cb.success();
      }

      await notify.success('Cadastro confirmado com sucesso!');

      await mapping.track('E-mail Confirmation');
    } catch (e) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const forgotPassword =
  ({ email }: any, cb?: any) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());

    try {
      await analytics.logEvent('user', 'forgot-password');

      const payload: any = await AuthRequests.forgotPassword(email);

      if (cb && cb.success) {
        cb.success();
      }

      await notify.success(
        'Caso exista uma conta cadastrada com esse e-mail, serão enviadas as instruções de recuperação.'
      );

      await mapping.track('Forgot Password');
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const passwordRecovery =
  ({ password, recoveryCode }: any, cb?: any) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());

    try {
      await analytics.logEvent('user', 'password-recovery');

      const payload: any = await AuthRequests.passwordRecovery(
        password,
        recoveryCode
      );

      if (cb && cb.success) {
        cb.success();
      }

      await notify.success('Senha alterada com sucesso!');

      await mapping.track('Password Recovery');
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const signOut = (user: any) => async (dispatch: any) => {
  dispatch(increaseLoading());

  try {
    localStorage.setItem('token', '');

    await analytics.logEvent('user', 'sign-out');

    const payload: any = {};

    // dispatch({
    //   payload,
    //   type: AUTH_SIGN_OUT
    // });

    await notify.goodbye('Até logo, ', user);

    await mapping.track('Sign Out');
  } catch (err) {
    if (err instanceof Error) {
      // console.log(err.message);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const checkToken =
  (location: any, navigate: any, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());

    try {
      await analytics.logEvent('user', 'token-sign-in');

      const payload: any = await AuthRequests.checkToken();

      const { token, user } = payload;

      // console.log(user);

      dispatch({
        payload,
        type: AUTH_CHECK_TOKEN
      });

      dispatch({
        payload: {
          _id: user._id || '',
          name: user.name || '',
          picture: user.picture || '',
          email: user.email || '',
          phone: user.phone || '',
          isSubscriber: user.isSubscriber || false,
          options: user.options || {},
          dictionary: user.dictionary || [],
          disabledRules: user.disabledRules || [],
          since: user.since || undefined,
          subscription: user.subscription || undefined,
          stripe_id: user.stripe_id || ''
        },
        type: USER_DETAIL
      });

      localStorage.setItem('token', token);

      localStorage.setItem('theme', user.options.theme || 'light');

      // // @ts-ignore
      // if (window.intercomSettings) {
      //   // @ts-ignore
      //   window.intercomSettings = {
      //     api_base: 'https://api-iam.intercom.io',
      //     app_id: 'awj7l5s9',
      //     name: user.name, // Nome completo
      //     email: user.email // Endereço de e-mail
      //   };
      // }

      await mapping.identify(user._id);

      await mapping.people.set({
        $name: user.name,
        $email: user.email
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        // await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getChurnKeyHash = (cb?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());

  try {
    const payload: any = await AuthRequests.getChurnKeyHash();

    if (cb && cb.success) {
      cb.success(payload);
    }
  } catch (e: any) {
    if (e) {
      if (cb && cb.error) {
        cb.error();
      }

      // await RequestErrorHelper(e);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};
