import React, { useState } from 'react';
import { ForgotPasswordFormInterface } from './interface';
import useStyles from './style';
import HeadForm from '../../../../components/HeadForm';
import TitleForm from '../../../../components/TitleForm';
import ButtonSubmit from '../../../../components/ButtonSubmit';
import Input from '../../../../components/Input';
import ChannelsContainer from '../../../../components/ChannelsContainer';
import ReCAPTCHA from 'react-google-recaptcha';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import { getLanguage } from '../../../../../../utils/function/getLanguage';

const ForgotPasswordForm = ({
  email,
  setEmail,
  captcha,
  setCaptcha,
  loading,
  onSubmit
}: ForgotPasswordFormInterface) => {
  const classes: any = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const [language, setLanguage] = useState(getLanguage());

  return (
    <div className={classes.root}>
      <div>
        <HeadForm
          title={translate('page.forgotPassword.form.action.firstAccess')}
          textButton={translate('page.forgotPassword.form.action.signUp')}
          routeButton="/sign-up"
        />

        <TitleForm
          title={translate('page.forgotPassword.form.title')}
          description={translate('page.forgotPassword.form.description')}
        />

        <form action="" onSubmit={onSubmit} className={classes.form}>
          <Input
            id="email"
            label={translate('page.forgotPassword.form.email.label')}
            placeholder={translate(
              'page.forgotPassword.form.email.placeholder'
            )}
            type="text"
            value={email}
            setValue={setEmail}
          />

          <ReCAPTCHA
            sitekey="6Lck3ToaAAAAAA-EZIPMqAA3nQ-PtyKhoa0K3oU5"
            onChange={() => setCaptcha(true)}
            onErrored={() => setCaptcha(false)}
            onExpired={() => setCaptcha(false)}
            className={classes.captcha}
            hl={language}
          />

          <ButtonSubmit disabled={loading || !captcha} loading={loading}>
            {translate('page.forgotPassword.form.action.sendEmail')}
          </ButtonSubmit>

          <ChannelsContainer />
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
