import React, { useState } from 'react';
import useStyles from './style';
import ContainerAuth from '../../components/ContainerAuth';
import LeftContainerFlow from '../../components/LeftContainerFlow';
import HeadAuth from '../../components/HeadAuth';
import SignUpForm from './components/SignUpForm';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../hooks/useReduxState';
import { signIn, signUp } from '../../../../stories/actions/auth';
import { useNavigate } from 'react-router-dom';
import notify from '../../../../utils/notify';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';

type Props = {};

const SignUp = (props: Props) => {
  const classes = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [accept, setAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { auth } = useReduxState();

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (name === '') {
      return notify.error(translate('page.signUp.notify.required.name'));
    }

    if (email === '') {
      return notify.error(translate('page.signUp.notify.required.email'));
    }

    if (password === '') {
      return notify.error(translate('page.signUp.notify.required.password'));
    }

    if (!loading && accept && captcha) {
      setLoading(true);

      const cb = {
        success: () => {
          setCaptcha(false);

          setLoading(false);

          navigate(`/sign-in`);
        },
        error: () => {
          setLoading(false);
        }
      };

      const education = localStorage.getItem('utm_source');

      const type = education ? 'education' : 'standard';

      dispatch(signUp({ name, email, password, accept, type }, cb));
    }
  };

  const handleChange = ({ target }: any) => {
    setAccept(target.checked);
  };

  return (
    <>
      <div className={classes.root}>
        <HeadAuth
          title={'Clarice.ai | ' + translate('page.signUp.title')}
          description={translate('page.signUp.description')}
        />
        <ContainerAuth>
          <LeftContainerFlow
            description={translate('page.signUp.leftContainerFlow.description')}
          />

          <SignUpForm
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            accept={accept}
            loading={loading}
            handleChange={handleChange}
            onSubmit={onSubmit}
            captcha={captcha}
            setCaptcha={setCaptcha}
          />
        </ContainerAuth>
      </div>
    </>
  );
};

export default SignUp;
