import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import FullLogo from '../../../../assets/images/jsx-icons/full-logo';
import PlanContainer from './components/planContainer';
import { getPlans } from '../../../../stories/actions/payment';
import { loadStripe } from '@stripe/stripe-js';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../hooks/useReduxState';
import withAuth from '../../../../utils/axios/withAuth';
import { ACTIVE_COUPON } from '../../../../utils/coupon';
import { useNavigate } from 'react-router-dom';
import NavBarPayment from '../payment/old/components/NavBar';
import Teste from '../../../../assets/images/payment/person.svg';
import { isBlackWeek } from '../../../../utils/function/isBlackWeek';
import { getPlatformVersion } from '../../../../utils/function/getPlatformVersion';

let stripe;

const keys = {
  live: 'pk_live_51HiMQkCh5qCS77obLnokWCFM1PbNO7GN1lxLyb92SEGel8C56eahoSddx8Le0gh5EZVvpSbBxyRiv0iizx7cmtiH00X15HmHg9',
  test: 'pk_test_51HiMQkCh5qCS77obmsIUxOvGLPittmEG4gsizSct7rqQYskaFIZGl28XoKtRY4ZhBXoRmqzvjREFmfB7XFcBpkQN00p8R5CVrj'
};

const formatPrice = (amount: number) =>
  String((amount / 100).toFixed(2)).replace('.', ',');

function getClientReferenceId() {
  return (
    // @ts-ignore
    (window.Rewardful && window.Rewardful.referral) ||
    'checkout_' + new Date().getTime()
  );
}

const Plans = () => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  const [planType, setPlanType] = useState('year');

  const [plans, setPlans] = useState<any>([]);

  const [word, setWord] = useState<any>('unlimited');

  const dispatch = useAppDispatch();

  const { payment, user } = useReduxState();

  const navigate = useNavigate();

  const onTogglePlanType = (type: string) => {
    setPlanType(type);
  };

  useEffect(() => {
    if (user.isSubscriber) {
      return navigate('/account/credits-billings');
    }
  }, [user.isSubscriber]);

  useEffect(() => {
    loadStripe(
      ['app-stg.clarice.ai', 'tests.clarice.ai', 'local-host'].includes(
        window.location.hostname
      )
        ? keys.test
        : keys.live,
      {
        locale: 'pt-BR'
      }
    ).then(s => {
      stripe = s;
    });
    // .catch(e => console.log(e));
  }, []);

  useEffect(() => {
    if (payment.plans) {
      const formatted: any = {
        revisora: {
          monthly: {
            custom: {
              title: 'Criativo',
              subtitle:
                'Para criadores de contéudo que precisam escalar seu trabalho',
              // description: 'Vá além da Gramática! Garanta uma escrita clara, concisa, forte e original com sugestões avançadas da Clarice.ai',
              description:
                'Com as sugestões avançadas da Clarice.ai, vá além da gramática e garanta uma escrita clara, concisa, forte e original.',
              benefits: [
                {
                  key: 1,
                  text: 'IA Revisora + IA Editora + IA Escritora'
                },
                {
                  key: 2,
                  text: '65+ templates'
                },
                {
                  key: 3,
                  text: 'ChatGPT'
                },
                {
                  key: 4,
                  text: 'Plugin Google Docs'
                },
                // {
                //   key: 5,
                //   text: '100 mil palavras geradas por IA'
                // },
                {
                  key: 6,
                  text: 'Até 50 textos'
                },
                {
                  key: 7,
                  text: 'Até 10 projetos'
                },
                {
                  key: 8,
                  text: 'Suporte via e-mail'
                }
              ],
              company: false,
              bestPlan: false
            }
          },
          yearly: {
            custom: {
              title: 'Criativo',
              subtitle:
                'Para criadores de contéudo que precisam escalar seu trabalho',
              description:
                'Gere até 100 mil palavras e tenha acesso a sugestões avançadas, garantindo clareza, concisão, força e originalidade em seu conteúdo.',
              benefits: [
                {
                  key: 1,
                  text: 'IA Revisora + IA Editora + IA Escritora'
                },
                {
                  key: 2,
                  text: '65+ templates'
                },
                {
                  key: 3,
                  text: 'ChatGPT'
                },
                {
                  key: 4,
                  text: 'Plugin Google Docs'
                },
                // {
                //   key: 5,
                //   text: '100 mil palavras geradas por IA'
                // },
                {
                  key: 6,
                  text: 'Até 50 textos'
                },
                {
                  key: 7,
                  text: 'Até 10 projetos'
                },
                {
                  key: 8,
                  text: 'Suporte via e-mail'
                }
              ],
              company: false,
              bestPlan: false
            }
          }
        },
        escritora: {
          monthly: {
            custom: {
              title: 'Profissional',
              subtitle:
                'Tudo o que você precisa para escrever profissionalmente 10x mais rápido',
              description:
                'Com as ferramentas de IA Revisora e IA Escritora, você tem à disposição poderosas soluções para escrever e revisar textos e conteúdos completos.',
              benefits: [
                {
                  key: 1,
                  text: 'IA Revisora + IA Editora + IA Escritora'
                },
                {
                  key: 2,
                  text: '65+ templates'
                },
                {
                  key: 3,
                  text: 'ChatGPT'
                },
                {
                  key: 4,
                  text: 'Plugin Google Docs'
                },
                // {
                //   key: 5,
                //   text: 'Palavras ilimitadas geradas por IA'
                // },
                {
                  key: 6,
                  text: 'Textos ilimitados'
                },
                {
                  key: 7,
                  text: 'Projetos ilimitados'
                },
                {
                  key: 8,
                  text: 'Suporte Premium'
                }
              ],
              company: false,
              bestPlan: true
            }
          },
          yearly: {
            custom: {
              title: 'Profissional',
              subtitle:
                'Tudo o que você precisa para escrever profissionalmente 10x mais rápido',
              // description:
              //   'Tudo do Essencial + ferramentas poderosas para escrever conteúdo completo (Documentos) e imagens únicas com Arte AI.',
              description:
                'Com as ferramentas de IA Revisora e IA Escritora, você tem à disposição poderosas soluções para escrever e revisar textos e conteúdos completos.',
              benefits: [
                {
                  key: 1,
                  text: 'IA Revisora + IA Editora + IA Escritora'
                },
                {
                  key: 2,
                  text: '65+ templates'
                },
                {
                  key: 3,
                  text: 'ChatGPT'
                },
                {
                  key: 4,
                  text: 'Plugin Google Docs'
                },
                // {
                //   key: 5,
                //   text: 'Palavras ilimitadas geradas por IA'
                // },
                {
                  key: 6,
                  text: 'Textos ilimitados'
                },
                {
                  key: 7,
                  text: 'Projetos ilimitados'
                },
                {
                  key: 8,
                  text: 'Suporte Premium'
                }
              ],
              company: false,
              bestPlan: true
            }
          }
        },
        company: {
          monthly: {
            custom: {
              title: 'Empresas',
              subtitle:
                'Para empresas que precisam de uma IA segura, escalável e feita sob medida',
              price: '',
              type: '',
              // description:
              //   'Obtenha pacotes personalizados de creditos/usuários e suporte para dimensionar seus resultados com todo potencial da Clarice.ai.',
              description:
                'Potencialize seus resultados com a Clarice.ai através de pacotes personalizados de créditos/usuários e suporte exclusivo.',
              benefits: [
                {
                  key: 1,
                  text: 'IA Revisora + IA Editora + IA Escritora'
                },
                {
                  key: 2,
                  text: 'Manual da Marca'
                },
                {
                  key: 3,
                  text: 'Mínimo de 5 membros'
                },
                {
                  key: 4,
                  text: 'Cobrança Centralizada'
                },
                {
                  key: 5,
                  text: 'Plugin Google Docs'
                },
                {
                  key: 6,
                  text: 'Gerenciamento de Equipe'
                },
                {
                  key: 7,
                  text: 'Treinamento e Suporte Premium'
                }
              ],
              company: true,
              bestPlan: false
            }
          },
          yearly: {
            custom: {
              title: 'Empresas',
              subtitle:
                'Para empresas que precisam de uma IA segura, escalável e feita sob medida',
              price: '',
              type: '',
              // description:
              //   'Obtenha pacotes personalizados de creditos/usuários e suporte para dimensionar seus resultados com todo potencial da Clarice.ai
              description:
                'Potencialize seus resultados com a Clarice.ai através de pacotes personalizados de créditos/usuários e suporte exclusivo.',
              benefits: [
                {
                  key: 1,
                  text: 'IA Revisora + IA Editora + IA Escritora'
                },
                {
                  key: 2,
                  text: 'Manual da Marca'
                },
                {
                  key: 3,
                  text: 'Mínimo de 5 membros'
                },
                {
                  key: 4,
                  text: 'Cobrança Centralizada'
                },
                {
                  key: 5,
                  text: 'Plugin Google Docs'
                },
                {
                  key: 6,
                  text: 'Gerenciamento de Equipe'
                },
                {
                  key: 7,
                  text: 'Treinamento e Suporte Premium'
                }
              ],
              company: true,
              bestPlan: false
            }
          }
        },
        lite: {
          monthly: {
            custom: {
              title: 'Mensal',
              company: false,
              bestPlan: false
            }
          },
          semiAnnual: {
            custom: {
              title: 'Semestral',
              company: false,
              bestPlan: false
            }
          },
          yearly: {
            custom: {
              title: 'Anual',
              company: false,
              bestPlan: true
            }
          }
        }
      };

      payment.plans.map(plan => {
        if (plan.nickname === 'Subscription Lite Monthly') {
          formatted.lite.monthly = {
            ...formatted.lite.monthly,
            ...plan
            // amount: 2990
          };
        }

        if (plan.nickname === 'Subscription Lite Semi-Annual') {
          formatted.lite.semiAnnual = {
            ...formatted.lite.semiAnnual,
            ...plan,
            amount: 2490,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                <>
                  Uma cobrança <b>semestral </b> de{' '}
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: 'rgb(255 0 74 / 86%)'
                    }}
                  >
                    <b>R$179,40</b>
                  </span>{' '}
                  <b>⭢ R$149,40</b>
                </>
              </div>
            )
          };
        }

        if (plan.nickname === 'Subscription Lite Yearly') {
          formatted.lite.yearly = {
            ...formatted.lite.yearly,
            ...plan,
            // amount: 2990,
            amount: 1990,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                <>
                  Uma cobrança <b>anual</b> de{' '}
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: 'rgb(255 0 74 / 86%)'
                    }}
                  >
                    <b>R$358,80</b>
                  </span>{' '}
                  <b>⭢ R$238,80</b>
                </>
              </div>
            )
          };
        }

        if (
          plan.nickname ===
          'Plano Mensal IA Escritora + IA Revisora | 50.000 palavras'
        ) {
          // if (plan.nickname === 'Plano Mensal IA Revisora') {
          //   formatted.revisora.monthly = {
          //     ...formatted.revisora.monthly,
          //     ...plan,
          //     amount: 2990
          //   };
          // }

          // if (plan.nickname === 'Plano Anual IA Revisora') {
          //   formatted.revisora.yearly = {
          //     ...formatted.revisora.yearly,
          //     ...plan,
          //     amount: 2290,
          //     yearlyMessage: 'Uma cobrança anual de R$274,90'
          //   };
          // }

          // 50K
          formatted.escritora.monthly = {
            ...formatted.escritora.monthly,
            plans: {
              ...formatted.escritora.monthly.plans,
              '50K': { ...plan }
            }
          };
        }

        // if (
        //   plan.nickname ===
        //   'Plano Anual IA Escritora + IA Revisora | 50.000 palavras'
        // ) {
        //   formatted.escritora.yearly = {
        //     ...formatted.escritora.yearly,
        //     plans: {
        //       ...formatted.escritora.yearly.plans,
        //       '50K': {
        //         ...plan,
        //         amount: 5990,
        //         yearlyMessage: 'Uma cobrança anual de R$718,80'
        //       }
        //     }
        //   };
        // }

        // 100K
        if (
          plan.nickname ===
          'Plano Mensal IA Escritora + IA Revisora | 100.000 palavras'
        ) {
          formatted.escritora.monthly = {
            ...formatted.escritora.monthly,
            plans: {
              ...formatted.escritora.monthly.plans,
              '100K': { ...plan }
            }
          };
        }

        // if (
        //   plan.nickname ===
        //   'Plano Anual IA Escritora + IA Revisora | 100.000 palavras'
        // ) {
        //   formatted.escritora.yearly = {
        //     ...formatted.escritora.yearly,
        //     plans: {
        //       ...formatted.escritora.yearly.plans,
        //       '100K': {
        //         ...plan,
        //         amount: 9990,
        //         yearlyMessage: 'Uma cobrança anual de R$1198,80'
        //       }
        //     }
        //   };
        // }

        // 250K
        if (
          plan.nickname ===
          'Plano Mensal IA Escritora + IA Revisora | 250.000 palavras'
        ) {
          formatted.escritora.monthly = {
            ...formatted.escritora.monthly,
            plans: {
              ...formatted.escritora.monthly.plans,
              '250K': { ...plan }
            }
          };
        }

        // if (
        //   plan.nickname ===
        //   'Plano Anual IA Escritora + IA Revisora | 250.000 palavras'
        // ) {
        //   console.log('PLAN: ', plan);
        //
        //   formatted.escritora.yearly = {
        //     ...formatted.escritora.yearly,
        //     plans: {
        //       ...formatted.escritora.yearly.plans,
        //       '250K': {
        //         ...plan,
        //         amount: 17990,
        //         yearlyMessage: 'Uma cobrança anual de R$2158,80'
        //       }
        //     }
        //   };
        // }

        // PARCELADO
        // if (
        //   plan.nickname === 'Plano Anual IA Revisora (Parcelado em 12 vezes)'
        // ) {
        //   formatted.revisora.yearly = {
        //     ...formatted.revisora.yearly,
        //     ...plan,
        //     amount: 2290,
        //     yearlyMessage: '12 parcelas de R$ 22,90'
        //   };
        // }

        if (
          plan.nickname ===
          'Plano Anual IA Escritora + IA Revisora | 50.000 palavras (Parcelado em 12 vezes)'
        ) {
          formatted.escritora.yearly = {
            ...formatted.escritora.yearly,
            plans: {
              ...formatted.escritora.yearly.plans,
              '50K': {
                ...plan,
                amount: 5990,
                yearlyMessage: isBlackWeek()
                  ? '12 parcelas de R$ 29,95'
                  : '12 parcelas de R$ 59,90'
              }
            }
          };
        }

        if (
          plan.nickname ===
          'Plano Anual IA Escritora + IA Revisora | 100.000 palavras (Parcelado em 12 vezes)'
        ) {
          formatted.escritora.yearly = {
            ...formatted.escritora.yearly,
            plans: {
              ...formatted.escritora.yearly.plans,
              '100K': {
                ...plan,
                amount: 9990,
                yearlyMessage: isBlackWeek()
                  ? '12 parcelas de R$ 49,95'
                  : '12 parcelas de R$ 99,90'
              }
            }
          };
        }

        if (
          plan.nickname ===
          'Plano Anual IA Escritora + IA Revisora | 250.000 palavras (Parcelado em 12 vezes)'
        ) {
          formatted.escritora.yearly = {
            ...formatted.escritora.yearly,
            plans: {
              ...formatted.escritora.yearly.plans,
              '250K': {
                ...plan,
                amount: 17990,
                yearlyMessage: isBlackWeek()
                  ? '12 parcelas de R$ 89,95'
                  : '12 parcelas de R$ 179,90'
              }
            }
          };
        }

        if (plan.nickname === 'Assinatura Mensal | Criativo') {
          formatted.revisora.monthly = {
            ...formatted.revisora.monthly,
            ...plan
          };
        }

        if (plan.nickname === 'Assinatura Anual | Criativo') {
          formatted.revisora.yearly = {
            ...formatted.revisora.yearly,
            ...plan,
            // yearlyMessage: (
            //   <>
            //     Equivalente a um valor mensal de <b>R$37,42</b>
            //   </>
            // )
            amount: 3800,
            yearlyMessage: (
              <div style={{ fontSize: '13.5px' }}>
                {ACTIVE_COUPON === 'ANUAL40' && (
                  <>
                    Uma cobrança <b>anual</b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$449,00</b>
                    </span>{' '}
                    <b>⭢ R$269,40</b>
                  </>
                )}

                {ACTIVE_COUPON === 'LINGUAPT50' && (
                  <>
                    Uma cobrança <b>anual</b> de{' '}
                    <span
                      style={{
                        textDecoration: 'line-through',
                        color: 'rgb(255 0 74 / 86%)'
                      }}
                    >
                      <b>R$449,00</b>
                    </span>{' '}
                    <b>⭢ R$224,50</b>
                  </>
                )}

                {ACTIVE_COUPON === 'NO_DISCOUNT' && (
                  <>
                    Uma cobrança <b>anual</b> de <b>R$449,00</b>
                  </>
                )}
              </div>
            )
          };
        }

        if (plan.nickname === 'Assinatura Mensal | Profissional') {
          formatted.escritora.monthly = {
            ...formatted.escritora.monthly,
            plans: {
              ...formatted.escritora.monthly.plans,
              unlimited: { ...plan }
            }
          };
        }

        if (plan.nickname === 'Assinatura Anual | Profissional') {
          formatted.escritora.yearly = {
            ...formatted.escritora.yearly,
            plans: {
              ...formatted.escritora.yearly.plans,
              unlimited: {
                ...plan,
                // yearlyMessage: (
                //   <>
                //     Equivalente a um valor mensal de <b>R$74,92</b>
                //   </>
                // )
                amount: 7500,
                yearlyMessage: (
                  <div style={{ fontSize: '13.5px' }}>
                    {ACTIVE_COUPON === 'ANUAL40' && (
                      <>
                        Uma cobrança <b>anual</b> de{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>R$899,00</b>
                        </span>{' '}
                        <b>⭢ R$539,40</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'LINGUAPT50' && (
                      <>
                        Uma cobrança <b>anual</b> de{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'rgb(255 0 74 / 86%)'
                          }}
                        >
                          <b>R$899,00</b>
                        </span>{' '}
                        <b>⭢ R$449,50</b>
                      </>
                    )}

                    {ACTIVE_COUPON === 'NO_DISCOUNT' && (
                      <>
                        Uma cobrança <b>anual</b> de <b>R$899,00</b>
                      </>
                    )}
                  </div>
                )
              }
            }
          };
        }

        return plan;
      });

      setPlans(formatted);
    }
  }, [payment.plans, planType]);

  const onRedirectToCheckout = async (priceId: string, type) => {
    // console.log({ priceId, type });

    const res = await withAuth().post(
      '/payments/create-checkout-session',
      ACTIVE_COUPON === 'NO_DISCOUNT' || type !== 'yearly'
        ? {
            priceId,
            client_reference_id: getClientReferenceId()
          }
        : {
            priceId,
            code: ACTIVE_COUPON,
            client_reference_id: getClientReferenceId()
          }
    );

    await stripe.redirectToCheckout(res.data);
  };

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box
      className={classes.root}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <NavBarPayment />
      <Box
        className={classes.planHeader}
        sx={{
          marginTop: isMobile ? '150px!important' : '80px!important',
          width: '100%',
          maxWidth: '1500px',
          display: 'flex',
          margin: '2rem 0'
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems="center"
          width={'100%'}
        >
          {!isMobile && (
            <Box className={classes.iconContainer}>
              <img src={Teste} alt="Person" />
            </Box>
          )}
          <Box>
            <Typography
              className={classes.title}
              style={isMobile ? { marginBottom: '15px' } : {}}
            >
              <strong>
                {isBlackWeek() && !user.isSubscriber
                  ? 'Black Friday na Clarice.ai! 🔥'
                  : 'Experimente grátis por 7 dias'}
              </strong>
            </Typography>
            <Typography
              className={classes.subtitle}
              style={isMobile ? { marginBottom: '20px' } : {}}
            >
              {isBlackWeek() && !user.isSubscriber ? (
                <>
                  Use o cupom <b>BLACKFRIDAY</b> para um desconto de <b>50%</b>{' '}
                  na assinatura anual da <b>IA Escritora + IA Revisora</b>!
                </>
              ) : (
                'E encontre o plano mais adequado às necessidades do seu negócio'
              )}
            </Typography>
          </Box>
        </Box>

        {getPlatformVersion(user) === 'standard' && (
          <Box className={classes.planSelect}>
            <Box
              sx={{
                display: 'flex',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                borderRadius: '1000px',
                width: '227px',
                height: '40.25px',
                // '-webkit-box-align': 'center',
                alignItems: 'center!important',
                justifyContent: 'center',
                padding: '2px'
              }}
            >
              <Typography
                sx={{
                  background: planType === 'month' ? '#7a26f8' : 'transparent',
                  borderRadius: '1000px',
                  cursor: 'pointer',
                  flex: '1 1 0%',
                  height: '100%',
                  display: 'flex',
                  '-webkit-box-pack': 'center',
                  justifyContent: 'center',
                  '-webkit-box-align': 'center',
                  alignItems: 'center',
                  color: planType === 'month' ? '#fff' : 'rgb(26, 32, 44)',
                  fontWeight: '700',
                  fontSize: '0.875em'
                }}
                onClick={() => onTogglePlanType('month')}
              >
                Mensal
              </Typography>
              <Typography
                sx={{
                  background: planType === 'year' ? '#7a26f8' : 'transparent',
                  borderRadius: '1000px',
                  cursor: 'pointer',
                  flex: '1 1 0%',
                  height: '100%',
                  display: 'flex',
                  '-webkit-box-pack': 'center',
                  justifyContent: 'center',
                  '-webkit-box-align': 'center',
                  alignItems: 'center',
                  color: planType === 'year' ? '#fff' : 'rgb(26, 32, 44)',
                  fontWeight: '700',
                  fontSize: '0.875em'
                }}
                onClick={() => onTogglePlanType('year')}
              >
                Anual
              </Typography>
            </Box>
            <Typography
              sx={{
                textAlign: 'right',
                fontSize: '0.775em',
                marginTop: '11px'
              }}
            >
              <strong>Economize 3 MESES</strong> no Plano Anual
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        className={classes.planContainer}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: '2rem',
          maxWidth: '1300px'
        }}
      >
        {payment.plans.length === 0 && (
          <p style={{ textAlign: 'center' }}>Carregando...</p>
        )}

        {getPlatformVersion(user) === 'standard' && (
          <>
            {payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.revisora &&
              plans.revisora?.monthly && (
                <PlanContainer
                  nickname={
                    plans.revisora[planType === 'month' ? 'monthly' : 'yearly']
                      .nickname
                  }
                  key={
                    plans.revisora[planType === 'month' ? 'monthly' : 'yearly']
                      .id
                  }
                  title={
                    plans.revisora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.title
                  }
                  subtitle={
                    plans.revisora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.subtitle
                  }
                  price={
                    plans.revisora[planType === 'month' ? 'monthly' : 'yearly']
                      .amount
                  }
                  yearlyMessage={
                    plans.revisora[planType === 'month' ? 'monthly' : 'yearly']
                      .yearlyMessage
                      ? plans.revisora[
                          planType === 'month' ? 'monthly' : 'yearly'
                        ].yearlyMessage
                      : null
                  }
                  type={planType}
                  benefits={
                    plans.revisora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.benefits
                  }
                  bestPlan={
                    plans.revisora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.bestPlan
                  }
                  company={
                    plans.revisora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.company
                  }
                  description={
                    plans.revisora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.description
                  }
                  onSelect={() =>
                    onRedirectToCheckout(
                      plans.revisora[
                        planType === 'month' ? 'monthly' : 'yearly'
                      ].id,
                      planType === 'month' ? 'monthly' : 'yearly'
                    )
                  }
                ></PlanContainer>
              )}

            {payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.escritora &&
              plans.escritora?.monthly?.plans &&
              plans.escritora?.yearly?.plans && (
                <PlanContainer
                  nickname={
                    plans.escritora[planType === 'month' ? 'monthly' : 'yearly']
                      .plans[word].nickname
                  }
                  key={
                    plans.escritora[planType === 'month' ? 'monthly' : 'yearly']
                      .plans[word].id
                  }
                  title={
                    plans.escritora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.title
                  }
                  subtitle={
                    plans.escritora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.subtitle
                  }
                  price={
                    plans.escritora[planType === 'month' ? 'monthly' : 'yearly']
                      .plans[word].amount
                  }
                  yearlyMessage={
                    plans.escritora[planType === 'month' ? 'monthly' : 'yearly']
                      .plans[word].yearlyMessage
                      ? plans.escritora[
                          planType === 'month' ? 'monthly' : 'yearly'
                        ].plans[word].yearlyMessage
                      : null
                  }
                  type={planType}
                  word={word}
                  setWord={setWord}
                  benefits={
                    plans.escritora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.benefits
                  }
                  bestPlan={
                    plans.escritora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.bestPlan
                  }
                  company={
                    plans.escritora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.company
                  }
                  description={
                    plans.escritora[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.description
                  }
                  onSelect={() =>
                    onRedirectToCheckout(
                      plans.escritora[
                        planType === 'month' ? 'monthly' : 'yearly'
                      ].plans[word].id,
                      planType === 'month' ? 'monthly' : 'yearly'
                    )
                  }
                ></PlanContainer>
              )}

            {payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.company && (
                <PlanContainer
                  key={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .id
                  }
                  title={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.title
                  }
                  subtitle={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.subtitle
                  }
                  price={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .price
                  }
                  type={planType}
                  benefits={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.benefits
                  }
                  bestPlan={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.bestPlan
                  }
                  company={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.company
                  }
                  description={
                    plans.company[planType === 'month' ? 'monthly' : 'yearly']
                      .custom.description
                  }
                  onSelect={() => {
                    window.open(
                      'https://form.typeform.com/to/kbyMgwPE',
                      '_blank'
                    );
                  }}
                ></PlanContainer>
              )}
          </>
        )}

        {getPlatformVersion(user) !== 'standard' && (
          <>
            {payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.lite &&
              plans.lite?.monthly && (
                <PlanContainer
                  nickname={plans.lite.monthly.nickname}
                  key={plans.lite.monthly.id}
                  title={plans.lite.monthly.custom.title}
                  subtitle={''}
                  price={plans.lite.monthly.amount}
                  yearlyMessage={''}
                  type={planType}
                  benefits={[]}
                  bestPlan={plans.lite.monthly.custom.bestPlan}
                  company={plans.lite.monthly.custom.company}
                  description={''}
                  onSelect={() =>
                    onRedirectToCheckout(plans.lite.monthly.id, 'monthly')
                  }
                ></PlanContainer>
              )}

            {payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.lite &&
              plans.lite?.yearly && (
                <PlanContainer
                  nickname={plans.lite.yearly.nickname}
                  key={plans.lite.yearly.id}
                  title={plans.lite.yearly.custom.title}
                  subtitle={''}
                  price={plans.lite.yearly.amount}
                  type={planType}
                  benefits={[]}
                  bestPlan={plans.lite.yearly.custom.bestPlan}
                  company={plans.lite.yearly.custom.company}
                  description={''}
                  yearlyMessage={plans.lite.yearly.yearlyMessage}
                  onSelect={() =>
                    onRedirectToCheckout(plans.lite.yearly.id, 'yearly')
                  }
                ></PlanContainer>
              )}

            {payment.plans &&
              payment.plans.length > 0 &&
              plans &&
              plans.lite &&
              plans.lite?.semiAnnual && (
                <PlanContainer
                  nickname={plans.lite.semiAnnual.nickname}
                  key={plans.lite.semiAnnual.id}
                  title={plans.lite.semiAnnual.custom.title}
                  subtitle={''}
                  price={plans.lite.semiAnnual.amount}
                  type={'planType'}
                  benefits={[]}
                  bestPlan={plans.lite.semiAnnual.custom.bestPlan}
                  company={plans.lite.semiAnnual.custom.company}
                  description={''}
                  yearlyMessage={plans.lite.semiAnnual.yearlyMessage}
                  onSelect={() =>
                    onRedirectToCheckout(plans.lite.semiAnnual.id, 'yearly')
                  }
                ></PlanContainer>
              )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Plans;
