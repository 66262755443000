import React, { useState } from 'react';
import { SignUpFormInterface } from './interface';
import useStyles from './style';
import HeadForm from '../../../../components/HeadForm';
import TitleForm from '../../../../components/TitleForm';
import GoogleButton from '../../../../components/GoogleButton';
import ButtonSubmit from '../../../../components/ButtonSubmit';
import LinkContainer from '../../../../components/LinkContainer';
import Input from '../../../../components/Input';
import CheckboxAcceptTerms from '../CheckboxAcceptTerms';
import ReCAPTCHA from 'react-google-recaptcha';
import TooltipContainer from '../../../../../App/components/TooltipContainer';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import { getLanguage } from '../../../../../../utils/function/getLanguage';

const SignUpForm = ({
  name,
  setName,
  email,
  setEmail,
  password,
  setPassword,
  accept,
  loading,
  handleChange,
  onSubmit,
  captcha,
  setCaptcha
}: SignUpFormInterface) => {
  const classes: any = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const tooltip = () => {
    if (name === '') {
      return translate('page.signUp.form.notify.required.name');
    }

    if (email === '') {
      return translate('page.signUp.form.notify.required.email');
    }

    if (password === '') {
      return translate('page.signUp.form.notify.required.password');
    }

    if (!captcha) {
      return translate('page.signUp.form.notify.required.captcha');
    }

    if (!accept) {
      return translate('page.signUp.form.notify.required.accept');
    }

    return '';
  };

  const [language, setLanguage] = useState(getLanguage());

  return (
    <div className={classes.root}>
      <div>
        <HeadForm
          title={translate('page.signUp.form.action.haveAnAccount')}
          textButton={translate('page.signUp.form.action.signIn')}
          routeButton="/sign-in"
        />

        <TitleForm title={translate('page.signUp.form.title')} />

        <GoogleButton>
          {translate('page.signUp.form.action.signUpWithGoogle')}
        </GoogleButton>

        <form action="" onSubmit={onSubmit} className={classes.form}>
          <Input
            id="name"
            label={translate('page.signUp.form.name.label')}
            placeholder={translate('page.signUp.form.name.placeholder')}
            type="text"
            value={name}
            setValue={setName}
          />
          <Input
            id="email"
            label={translate('page.signUp.form.email.label')}
            placeholder={translate('page.signUp.form.email.placeholder')}
            type="text"
            value={email}
            setValue={setEmail}
          />
          <Input
            id="senha"
            label={translate('page.signUp.form.password.label')}
            placeholder={translate('page.signUp.form.password.placeholder')}
            type="password"
            value={password}
            setValue={setPassword}
          />
          <ReCAPTCHA
            sitekey="6Lck3ToaAAAAAA-EZIPMqAA3nQ-PtyKhoa0K3oU5"
            onChange={() => setCaptcha(true)}
            onErrored={() => setCaptcha(false)}
            onExpired={() => setCaptcha(false)}
            className={classes.captcha}
            hl={language}
          />
          <LinkContainer>
            <CheckboxAcceptTerms accept={accept} handleChange={handleChange} />
          </LinkContainer>

          <TooltipContainer title={tooltip()} placement="bottom">
            <div>
              <ButtonSubmit
                disabled={loading || !accept || !captcha}
                loading={loading}
              >
                {translate('page.signUp.form.action.signUp')}
              </ButtonSubmit>
            </div>
          </TooltipContainer>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
