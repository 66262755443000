import { createContext, useEffect, useState } from 'react';
import {
  GlobalContextInterface,
  GlobalContextInterfaceProvider
} from './interface';
import GlobalContextInitial from './state';
import { useReduxState } from '../../../hooks/useReduxState';
import {
  updateChatHistoric,
  updateLanguage,
  updateTheme
} from '../../../stories/actions/user';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { TranslateInterface } from '../../interfaces/TranslateInterface';
import useLanguage from '../../hooks/useLanguage';
import { getLanguage } from '../../function/getLanguage';

export const GlobalContext =
  createContext<GlobalContextInterface>(GlobalContextInitial);

export const GlobalContextProvider = (
  props: GlobalContextInterfaceProvider
) => {
  const { user }: any = useReduxState();

  // const beta = ![
  //   'felipeiszlaji@gmail.com',
  //   'eurafaelcamillo@gmail.com',
  //   'nojap50172@soombo.com',
  //   'noxor19956@kyrescu.com',
  //   'koneg97416@jobbrett.com'
  // ].includes(user.detail.email);

  const beta = false;

  const translation: TranslateInterface = useLanguage();

  const [language, setLanguage] = useState(getLanguage());

  const [openModalCreateProject, setOpenModalCreateProject] = useState(false);

  const [openModalSuggestion, setOpenModalSuggestion] = useState(false);

  const [openModalNews, setOpenModalNews] = useState(false);

  const [openModalConversion, setOpenModalConversion] = useState(false);

  const [openModalOnboarding, setOpenModalOnboarding] = useState(false);

  const [openModalDisabledRules, setOpenModalDisabledRules] = useState(false);

  const [openModalDictionary, setOpenModalDictionary] = useState(false);

  const [openModalCorrections, setOpenModalCorrections] = useState(false);

  const [chatHistoric, setChatHistoric] = useState(false);

  const [openModalAcceptedTerms, setOpenModalAcceptedTerms] = useState(false);

  const dispatch = useAppDispatch();

  const toggleLanguage = (language: any) => {
    translation.settings.changeLanguage(language);

    localStorage.setItem('i18nextLng', language);

    setLanguage(language);

    dispatch(updateLanguage(language));
  };

  const toggleChatHistoric = (active: boolean) => {
    dispatch(updateChatHistoric(!chatHistoric));

    setChatHistoric(!chatHistoric);
  };

  const toggleModalCreateProject = () => {
    setOpenModalCreateProject(!openModalCreateProject);
  };

  const toggleModalSuggestion = () => {
    setOpenModalSuggestion(!openModalSuggestion);
  };

  const toggleModalNews = () => {
    setOpenModalNews(!openModalNews);
  };

  const toggleModalConversion = () => {
    setOpenModalConversion(!openModalConversion);
  };

  const toggleModalOnboarding = () => {
    setOpenModalOnboarding(!openModalOnboarding);
  };

  const toggleModalDisabledRules = () => {
    setOpenModalDisabledRules(!openModalDisabledRules);
  };

  const toggleModalDictionary = () => {
    setOpenModalDictionary(!openModalDictionary);
  };

  const toggleModalCorrections = () => {
    setOpenModalCorrections(!openModalCorrections);
  };

  const toggleModalAcceptedTerms = () => {
    setOpenModalAcceptedTerms(!openModalAcceptedTerms);
  };

  useEffect(() => {
    const { options }: any = user;

    if (options && options.chatHistoric) {
      setChatHistoric(!!options.chatHistoric);
    }

    if (options && options.acceptedTerms) {
      setOpenModalAcceptedTerms(!!options.acceptedTerms);
    }

    if (options && options.language) {
      let formatted = 'pt';

      if (options.language === 'PT-BR') {
        formatted = 'pt';
      }

      if (options.language === 'EN-US') {
        formatted = 'en';
      }

      if (options.language === 'ES-ES') {
        formatted = 'es';
      }

      setLanguage(formatted);

      translation.settings.changeLanguage(formatted);

      localStorage.setItem('i18nextLng', formatted);
    }
  }, [user.options]);

  return (
    <GlobalContext.Provider
      value={{
        beta,
        language,
        toggleLanguage,
        chatHistoric: {
          active: chatHistoric,
          toggle: toggleChatHistoric
        },
        modal: {
          project: {
            create: {
              open: openModalCreateProject,
              toggle: toggleModalCreateProject
            }
          },
          suggestion: {
            open: openModalSuggestion,
            toggle: toggleModalSuggestion
          },
          news: {
            open: openModalNews,
            toggle: toggleModalNews
          },
          conversion: {
            open: openModalConversion,
            toggle: toggleModalConversion
          },
          onboarding: {
            open: openModalOnboarding,
            toggle: toggleModalOnboarding
          },
          disabledRules: {
            open: openModalDisabledRules,
            toggle: toggleModalDisabledRules
          },
          dictionary: {
            open: openModalDictionary,
            toggle: toggleModalDictionary
          },
          corrections: {
            open: openModalCorrections,
            toggle: toggleModalCorrections
          },
          acceptedTerms: {
            open: openModalAcceptedTerms,
            toggle: toggleModalAcceptedTerms
          }
        }
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
