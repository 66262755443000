import React, { useContext, useEffect, useMemo, useState } from 'react';
import useStyles from './style';
import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import DocumentCard from '../documents/components/DocumentsCard';
import SearchBar from '../documents/components/SearchBar';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../hooks/useReduxState';
import { getAllDocument } from '../../../../stories/actions/documents';
import DocumentNotFound from '../documents/components/DocumentNotFound';
import BannerGoogleDocs from '../../components/BannerGoogleDocs';
import BannerDiscount from '../../components/BannerDiscount';
import { ACTIVE_COUPON } from '../../../../utils/coupon';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';
import { getPlatformVersion } from '../../../../utils/function/getPlatformVersion';
import Tools from '../../components/Tools';
const HomePage = () => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const { translate }: TranslateInterface = useLanguage();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const params = useParams();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);

  const dispatch = useAppDispatch();
  const { documents, user } = useReduxState();

  useEffect(() => {
    if (!loaded) {
      const cb = {
        success: () => {
          setLoaded(true);
        },
        error: () => {
          setLoaded(false);
        }
      };

      dispatch(getAllDocument(cb));
    }
  }, [dispatch, loaded]);

  const [search, setSearch] = useState('');

  const [filteredByProject, setFilteredByProject] = useState<any>([]);

  const { options }: any = user;

  useEffect(() => {
    const filtered = documents.all?.filter((document: any) => {
      if (options?.project?._id && document?.project?._id) {
        return String(document.project._id) === String(options.project._id);
      }

      return false;
    });

    if (options && options?.project?._id) {
      setFilteredByProject(filtered);
    }
  }, [options, documents]);

  const documentsFilter = useMemo(() => {
    const lowerSearch = search.toLowerCase();

    return filteredByProject.filter(document => {
      return document.title.toLowerCase().includes(lowerSearch);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className={classes.root}>
      {!params.type && (
        <>
          <Box>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              {/*{isBlackWeek() &&*/}
              {/*(!user.isSubscriber ||*/}
              {/*  [*/}
              {/*    'Plano Mensal IA Revisora',*/}
              {/*    'Plano Anual IA Revisora (Parcelado em 12 vezes)'*/}
              {/*  ].includes(user?.options?.plan?.type)) ? (*/}
              {/*  <BannerBlackFriday />*/}
              {/*) : (*/}
              {/*  <BannerNews />*/}
              {/*)}*/}

              {!user.isSubscriber &&
                ACTIVE_COUPON !== 'NO_DISCOUNT' &&
                getPlatformVersion(user) === 'standard' && <BannerDiscount />}

              {(user.isSubscriber ||
                ACTIVE_COUPON === 'NO_DISCOUNT' ||
                getPlatformVersion(user) !== 'standard') && (
                <BannerGoogleDocs />
              )}

              {getPlatformVersion(user) === 'education' && <Tools />}

              {getPlatformVersion(user) === 'standard' && (
                <>
                  <SearchBar value={search} setValue={setSearch}></SearchBar>
                  <Box className={classes.templates}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
                      <Grid
                        container
                        spacing={{ xs: 0, sm: 0, md: 2, lg: 2, xl: 2 }}
                      >
                        {search !== '' && documentsFilter.length === 0 && (
                          <>
                            <DocumentNotFound>
                              {translate('page.documents.notFound')}
                            </DocumentNotFound>
                          </>
                        )}

                        {search === '' && filteredByProject.length === 0 && (
                          <>
                            <DocumentNotFound>
                              {loaded
                                ? translate('page.documents.notAdded')
                                : translate('page.documents.loading')}
                            </DocumentNotFound>
                          </>
                        )}
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        display: 'grid',
                        gap: '1.5rem',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        [theme.breakpoints.down(1250)]: {
                          gridTemplateColumns: '1fr 1fr'
                        },
                        [theme.breakpoints.down(900)]: {
                          gridTemplateColumns: '1fr',
                          gap: '0'
                        }
                      }}
                    >
                      {search !== '' &&
                        documentsFilter.length > 0 &&
                        documentsFilter?.map(document => (
                          <DocumentCard
                            key={document._id}
                            id={document._id}
                            title={document.title}
                            description={document.sampleText}
                            date={document.updatedAt}
                            diversion={document.correctionsQuantity.total}
                            onSelect={() => {}}
                          />
                        ))}

                      {search === '' &&
                        filteredByProject?.length > 0 &&
                        filteredByProject?.map(document => (
                          <DocumentCard
                            key={document._id}
                            id={document._id}
                            title={document.title}
                            description={document.sampleText}
                            date={document.updatedAt}
                            diversion={document.correctionsQuantity.total}
                            onSelect={() => {}}
                          />
                        ))}
                    </Box>
                  </Box>
                </>
              )}
            </div>
          </Box>
        </>
      )}
    </div>
  );
};

export default HomePage;
