import { Box, Button, Slider, Typography, useTheme } from '@mui/material';
import GuaranteeIcon from '../../../../../../assets/images/checked-guarantee.svg';
import './styles.css';
import { Height } from '@mui/icons-material';
import { useState } from 'react';
import { isBlackWeek } from '../../../../../../utils/function/isBlackWeek';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { getPlatformVersion } from '../../../../../../utils/function/getPlatformVersion';

type PlanContainerProps = {
  nickname?: any;
  title?: any;
  subtitle?: any;
  price?: any;
  type?: any;
  word?: any;
  setWord?: any;
  description?: any;
  benefits?: any;
  company?: any;
  bestPlan?: any;
  onSelect?: any;
  yearlyMessage?: any;
};

const PlanContainer = ({
  nickname,
  title,
  subtitle,
  price,
  type,
  description,
  benefits,
  company,
  bestPlan,
  onSelect,
  word,
  setWord,
  yearlyMessage
}: PlanContainerProps) => {
  const formatPrice = (amount: number) =>
    String((amount / 100).toFixed(2)).replace('.', ',');

  const theme = useTheme();

  const marks = [
    {
      value: 1,
      label: '50K'
    },
    {
      value: 2,
      label: '100K'
    },
    {
      value: 3,
      label: '250K'
    }
  ];

  const valueLabelFormat = (value: number) => {
    // console.log(value);
    switch (value) {
      case 1:
        return `50K`;
      case 2:
        return `100K`;
      case 3:
        return `250K`;
      default:
        return `50K`;
    }
  };

  const valueLabelDefault = (value: string) => {
    // console.log(value);
    switch (value) {
      case '50K':
        return 1;
      case '100K':
        return 2;
      case '250K':
        return 3;
      default:
        return 1;
    }
  };

  const [value, setValue] = useState<number>(valueLabelDefault(word));

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
      setWord(valueLabelFormat(newValue));
    }
  };

  const { payment, user } = useReduxState();

  return (
    <>
      {company ? (
        <Box
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.1)',
            boxShadow: 'rgba(33, 37, 41, 0.1) 0px 8px 48px -8px',
            padding: '30px',
            borderRadius: '12px',
            textAlign: 'center',
            width: '100%',
            maxWidth: '425px',
            minHeight: '650px',
            marginBottom: '28px',
            placeSelf: 'center'
          }}
        >
          <div style={{ minHeight: '515px' }}>
            <Typography
              sx={{
                fontSize: '1.8em',
                lineHeight: '1em',
                fontWeight: '700',
                marginBottom: '12px',
                color: bestPlan ? '#fff' : 'rgb(65, 10, 133)'
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                margin: '0',
                color: 'rgb(113, 128, 150)',
                fontWeight: '400',
                fontSize: '0.875em'
              }}
            >
              {subtitle}
            </Typography>
            <Typography
              sx={{
                fontSize: '48px',
                fontWeight: 700,
                margin: '1rem 0',
                lineHeight: '60px',
                color: bestPlan ? '#fff' : '#000'
              }}
            >
              Consultar
            </Typography>
            {/*<Typography*/}
            {/*  sx={{*/}
            {/*    color: 'rgb(113, 128, 150)',*/}
            {/*    fontSize: '0.875em',*/}
            {/*    fontWeight: '500',*/}
            {/*    textAlign: 'left',*/}
            {/*    lineHeight: '150%',*/}
            {/*    letterSpacing: '0.3px'*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {description}*/}
            {/*</Typography>*/}

            <Typography
              sx={{
                margin: '0 0 1rem 0',
                fontWeight: '500',
                fontSize: '1.175em',
                color: '#410A85',
                // backgroundColor: 'rgb(239 239 239 / 78%)',
                paddingTop: '15px',
                paddingBottom: '15px',
                marginLeft: '10px',
                marginRight: '10px',
                borderRadius: '4px'
              }}
            >
              Cobranças personalizadas
            </Typography>

            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              sx={{ width: '100%', textAlign: 'left', margin: '1rem 0' }}
            >
              {benefits.map(item => {
                return (
                  <Box
                    key={item.key}
                    display={'flex'}
                    alignItems={'center'}
                    sx={{ marginBottom: '10px' }}
                  >
                    <img
                      src={GuaranteeIcon}
                      alt="GuaranteeIcon"
                      style={{ width: '2rem' }}
                    />
                    <Typography
                      sx={{
                        fontSize: '0.875em',
                        fontWeight: '500',
                        color: 'rgb(0, 0, 0)',
                        display: 'flex',
                        '-webkit-box-align': 'center',
                        alignItems: 'center'
                      }}
                      key={item.key}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                );
              })}
            </Box>

            <Box
              display={'flex'}
              // alignItems={'center'}
              sx={{ minHeight: '50px' }}
            />
          </div>

          <Button
            sx={{
              display: 'flex',
              '-webkit-box-align': 'center',
              alignItems: 'center',
              '-webkit-box-pack': 'center',
              justifyContent: 'center',
              color: bestPlan ? '#fff' : '#000',
              cursor: 'pointer',
              width: '100%',
              textTransform: 'none',
              borderRadius: '6px',
              backgroundColor: bestPlan ? '#000' : 'transparent',
              border: bestPlan ? 'none' : ' 1px solid rgb(203, 213, 224)',
              fontSize: '15px',
              fontWeight: 'bold',
              padding: '20px',
              '&:hover': {
                backgroundColor: bestPlan ? '#fff' : '#000',
                color: bestPlan ? '#000' : '#fff'
              }
            }}
            onClick={onSelect}
          >
            Entre em contato ➜
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            boxShadow: 'rgba(33, 37, 41, 0.1) 0px 8px 48px -8px',
            position: 'relative',
            // backgroundColor: bestPlan ? '#7a26f8' : 'none',
            // backgroundColor: bestPlan ? '#665481' : 'none',
            backgroundColor: bestPlan
              ? isBlackWeek() && !user.isSubscriber
                ? '#000000'
                : '#410A85'
              : 'none',
            border: bestPlan ? 'none' : '1px solid rgba(0, 0, 0, 0.1)',
            padding: '30px',
            borderRadius: '12px',
            textAlign: 'center',
            width: '100%',
            maxWidth: '425px',
            minHeight: bestPlan
              ? getPlatformVersion(user) === 'standard'
                ? '760px'
                : '410px'
              : getPlatformVersion(user) === 'standard'
              ? '650px'
              : '400px',
            marginBottom: '28px',
            placeSelf: 'center'
          }}
        >
          {bestPlan && (
            <Box
              sx={{
                display: 'block',
                padding: '4px 8px',
                // border: '1px solid rgb(26, 32, 44)',
                borderRadius: '6px',
                background: 'rgb(254, 197, 210)',
                position: 'absolute',
                top: '-12px',
                right: '13px'
              }}
            >
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '0.875em',
                  color: 'rgb(65, 10, 133)'
                }}
              >
                {isBlackWeek() && !user.isSubscriber
                  ? 'Black Friday 🔥'
                  : 'Melhor Plano'}
              </Typography>
            </Box>
          )}
          <div
            style={{
              minHeight:
                getPlatformVersion(user) === 'standard' ? '515px' : '250px'
            }}
          >
            <Typography
              sx={{
                fontSize: '1.8em',
                lineHeight: '1em',
                fontWeight: '700',
                marginBottom: '12px',
                color: bestPlan ? '#fff' : 'rgb(65, 10, 133)'
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                margin: '0',
                color: bestPlan
                  ? theme.palette.text.lightInfo
                  : 'rgb(113, 128, 150)',
                fontWeight: '400',
                fontSize: '0.875em'
              }}
            >
              {subtitle}
            </Typography>

            {type !== 'month' && isBlackWeek() && bestPlan && (
              <Typography
                sx={{
                  fontSize: '40px',
                  fontWeight: 700,
                  margin: '1rem 0 0 0',
                  lineHeight: '60px',
                  color: bestPlan ? '#ffffff94' : '#000',
                  textDecoration: 'line-through',
                  textDecorationColor: 'red'
                }}
              >
                {`R$ ${formatPrice(price)}`}{' '}
                <span style={{ fontSize: '18px' }}>
                  {type !== 'month' ? '/ mês' : ''}
                </span>
              </Typography>
            )}

            <Typography
              sx={{
                fontSize: '48px',
                fontWeight: 700,
                margin: isBlackWeek() ? '0.5rem 0px 0px' : '1rem 0 0 0',
                lineHeight: '60px',
                color: bestPlan ? '#fff' : '#000',
                marginBottom: '10px'
              }}
            >
              <span>
                {`R$${
                  type !== 'month' && bestPlan && isBlackWeek()
                    ? formatPrice(price * 0.5)
                    : formatPrice(price)
                }`}
              </span>

              <span style={{ fontSize: '18px' }}>
                {' '}
                {type !== 'month' ? '/ mês' : ''}
              </span>
            </Typography>

            <Typography
              sx={{
                margin: '0 0 1rem 0',
                fontWeight: '400',
                fontSize: '0.875em',
                color: bestPlan ? '#4a4a4a' : '#4a4a4a',
                backgroundColor: bestPlan ? '#ffffff' : 'transparent',
                paddingTop: '8px',
                paddingBottom: '8px',
                marginLeft: type === 'month' ? '50px' : '5px',
                marginRight: type === 'month' ? '50px' : '5px',
                borderRadius: '4px'
              }}
            >
              {type === 'month' ? (
                <>
                  por mês no <b>Plano Mensal</b>
                </>
              ) : yearlyMessage ? (
                yearlyMessage
              ) : getPlatformVersion(user) === 'standard' ? (
                'cobrado anualmente'
              ) : (
                'pagamento recorrente'
              )}
            </Typography>

            {getPlatformVersion(user) === 'standard' && (
              <Typography
                sx={{
                  margin: '0 0 1rem 0',
                  fontWeight: bestPlan ? '500' : '500',
                  fontSize: '1.175em',
                  color: bestPlan ? '#ffffff' : '#410A85',
                  // backgroundColor: bestPlan
                  //   ? '#7a26f8'
                  //   : 'rgb(239 239 239 / 78%)',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  borderRadius: '4px'
                }}
              >
                {bestPlan
                  ? 'Sem limite de palavras'
                  : '100 mil palavras por mês'}
              </Typography>
            )}

            {/*{bestPlan && (*/}
            {/*  <>*/}
            {/*    <Slider*/}
            {/*      sx={{*/}
            {/*        color: 'white',*/}
            {/*        marginTop: '30px',*/}
            {/*        marginBottom: '0px',*/}
            {/*        width: '250px',*/}
            {/*        height: '8px',*/}
            {/*        '.MuiSlider-markLabel': {*/}
            {/*          color: 'white'*/}
            {/*        }*/}
            {/*      }}*/}
            {/*      min={1}*/}
            {/*      max={3}*/}
            {/*      step={null}*/}
            {/*      // defaultValue={1}*/}
            {/*      value={value}*/}
            {/*      aria-label="Default"*/}
            {/*      // valueLabelDisplay="auto"*/}
            {/*      color="primary"*/}
            {/*      marks={marks}*/}
            {/*      onChange={handleChange}*/}
            {/*    />*/}

            {/*    <Typography*/}
            {/*      id="non-linear-slider"*/}
            {/*      style={{*/}
            {/*        fontSize: '0.875em',*/}
            {/*        fontWeight: '400',*/}
            {/*        color: '#fff',*/}
            {/*        marginTop: '0rem',*/}
            {/*        marginBottom: '1rem'*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <strong>{valueLabelFormat(value)}</strong>*/}
            {/*      {` palavras/mês`}*/}
            {/*    </Typography>*/}
            {/*  </>*/}
            {/*)}*/}
            {/*<Typography*/}
            {/*  sx={{*/}
            {/*    color: bestPlan ? '#f8f8f8' : 'rgb(113, 128, 150)',*/}
            {/*    fontSize: '0.875em',*/}
            {/*    fontWeight: '500',*/}
            {/*    textAlign: 'left',*/}
            {/*    lineHeight: '150%',*/}
            {/*    letterSpacing: '0.3px'*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {description}*/}
            {/*</Typography>*/}
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              sx={{ width: '100%', textAlign: 'left', margin: '1rem 0' }}
            >
              {benefits.map(item => {
                return (
                  <Box
                    key={item.key}
                    display={'flex'}
                    alignItems={'center'}
                    sx={{ marginBottom: '10px' }}
                  >
                    <img
                      src={GuaranteeIcon}
                      alt="GuaranteeIcon"
                      style={{ width: '2rem' }}
                    />
                    <Typography
                      sx={{
                        fontSize: '0.875em',
                        fontWeight: '500',
                        color: bestPlan ? '#fff' : '#000',
                        display: 'flex',
                        '-webkit-box-align': 'center',
                        alignItems: 'center'
                      }}
                      key={item.key}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                );
              })}

              {nickname &&
                [
                  'Plano Mensal IA Revisora',
                  'Plano Anual IA Revisora',
                  'Plano Anual IA Revisora (Parcelado em 12 vezes)'
                ].includes(nickname) && (
                  <Box
                    display={'flex'}
                    // alignItems={'center'}
                    sx={{ marginTop: '20px', marginBottom: '10px' }}
                  >
                    <Typography
                      sx={{
                        fontSize: '0.875em',
                        fontWeight: '500',
                        color: 'rgb(82 82 82)',
                        lineHeight: '1.9'
                      }}
                    >
                      Ganhe 10 mil palavras da AI Escritora para experimentar
                      <span
                        style={{
                          padding: '2px 6px',
                          // border: '1px solid rgb(26, 32, 44)',
                          borderRadius: '6px',
                          background: 'rgb(15, 237, 178)',
                          position: 'absolute',
                          marginLeft: '5px',
                          fontWeight: '500',
                          fontSize: '0.875em',
                          color: 'rgb(65, 10, 133)'
                        }}
                      >
                        Bônus
                      </span>
                    </Typography>
                  </Box>
                )}
            </Box>
          </div>
          <Button
            sx={{
              // marginTop: bestPlan ? '0px' : '5.5rem',
              display: 'flex',
              '-webkit-box-align': 'center',
              alignItems: 'center',
              '-webkit-box-pack': 'center',
              justifyContent: 'center',
              color: bestPlan ? 'rgb(65, 10, 133)' : '#000000',
              cursor: 'pointer',
              width: '100%',
              textTransform: 'none',
              borderRadius: '6px',
              backgroundColor: bestPlan ? 'rgb(254, 197, 210)' : 'transparent',
              border: bestPlan ? 'none' : ' 1px solid rgb(203, 213, 224)',
              fontSize: '15px',
              fontWeight: 'bold',
              padding: '20px',
              '&:hover': {
                backgroundColor: bestPlan ? 'rgb(250,179,195)' : '#000000',
                color: bestPlan ? 'rgb(65, 10, 133)' : '#fff'
              }
            }}
            onClick={onSelect}
          >
            {isBlackWeek() ? 'Comprar agora ➜' : 'Iniciar teste grátis ➜'}
          </Button>
        </Box>
      )}
    </>
  );
};

export default PlanContainer;
