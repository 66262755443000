import { getPlatformVersion } from '../../../../../utils/function/getPlatformVersion';

export const STATE_TEMPLATE = (
  themeMode: any,
  options?: any,
  translate?: any,
  user?: any
) => {
  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  if (!translate) {
    return {
      templates: []
    };
  }

  const platform = getPlatformVersion(user);

  if (platform === 'education') {
    return {
      templates: [
        {
          id: 'rewrite-text',
          title: translate('page.templates.list.rewriteText.title'),
          description: translate('page.templates.list.rewriteText.description'),
          type: [options.marketing, options.socialMedia],
          redirect: 'rewrite-text',
          imageUrl: baseURL + 'content-improver.svg',
          fields: {
            content: {
              label: translate(
                'page.templates.list.rewriteText.fields.content.label'
              ),
              placeholder: translate(
                'page.templates.list.rewriteText.fields.content.placeholder'
              ),
              rows: 12,
              required: true
            },
            tone: {
              label: translate(
                'page.templates.list.rewriteText.fields.tone.label'
              ),
              placeholder: translate(
                'page.templates.list.rewriteText.fields.tone.placeholder'
              )
            }
          }
        },
        {
          id: 'content-summarizer',
          title: translate('page.templates.list.contentSummarizer.title'),
          description: translate(
            'page.templates.list.contentSummarizer.description'
          ),
          type: [options.tools],
          redirect: 'content-summarizer',
          imageUrl: baseURL + 'pencil.svg',
          fields: {
            content: {
              label: translate(
                'page.templates.list.contentSummarizer.fields.content.label'
              ),
              placeholder: translate(
                'page.templates.list.contentSummarizer.fields.content.placeholder'
              ),
              rows: 12
            },
            count: {
              label: translate(
                'page.templates.list.contentSummarizer.fields.count.label'
              ),
              placeholder: translate(
                'page.templates.list.contentSummarizer.fields.count.placeholder'
              ),
              options: [
                {
                  value: '50',
                  description: translate(
                    'page.templates.list.contentSummarizer.fields.count.options.50'
                  )
                },
                {
                  value: '100',
                  description: translate(
                    'page.templates.list.contentSummarizer.fields.count.options.100'
                  )
                },
                {
                  value: '200',
                  description: translate(
                    'page.templates.list.contentSummarizer.fields.count.options.200'
                  )
                },
                {
                  value: '300',
                  description: translate(
                    'page.templates.list.contentSummarizer.fields.count.options.300'
                  )
                },
                {
                  value: '400',
                  description: translate(
                    'page.templates.list.contentSummarizer.fields.count.options.400'
                  )
                }
              ],
              required: true
            }
          }
        }
      ]
    };
  }

  return {
    templates: [
      {
        id: 'content-summarizer',
        title: translate('page.templates.list.contentSummarizer.title'),
        description: translate(
          'page.templates.list.contentSummarizer.description'
        ),
        type: [options.tools],
        redirect: 'content-summarizer',
        imageUrl: baseURL + 'pencil.svg',
        fields: {
          content: {
            label: translate(
              'page.templates.list.contentSummarizer.fields.content.label'
            ),
            placeholder: translate(
              'page.templates.list.contentSummarizer.fields.content.placeholder'
            ),
            rows: platform === 'standard' ? 6 : 12
          },
          count: {
            label: translate(
              'page.templates.list.contentSummarizer.fields.count.label'
            ),
            placeholder: translate(
              'page.templates.list.contentSummarizer.fields.count.placeholder'
            ),
            options: [
              {
                value: '50',
                description: translate(
                  'page.templates.list.contentSummarizer.fields.count.options.50'
                )
              },
              {
                value: '100',
                description: translate(
                  'page.templates.list.contentSummarizer.fields.count.options.100'
                )
              },
              {
                value: '200',
                description: translate(
                  'page.templates.list.contentSummarizer.fields.count.options.200'
                )
              },
              {
                value: '300',
                description: translate(
                  'page.templates.list.contentSummarizer.fields.count.options.300'
                )
              },
              {
                value: '400',
                description: translate(
                  'page.templates.list.contentSummarizer.fields.count.options.400'
                )
              }
            ],
            required: true
          }
        }
      },
      {
        id: 'facebook-ads-description',
        title: translate('page.templates.list.facebookAdsDescription.title'),
        description: translate(
          'page.templates.list.facebookAdsDescription.description'
        ),
        type: [options.socialMedia, options.marketing],
        redirect: 'facebook-ads-description',
        imageUrl: baseURL + 'facebook.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.facebookAdsDescription.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.facebookAdsDescription.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.facebookAdsDescription.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.facebookAdsDescription.fields.description.placeholder'
            ),
            rows: 4
          },
          audience: {
            label: translate(
              'page.templates.list.facebookAdsDescription.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.facebookAdsDescription.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.facebookAdsDescription.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.facebookAdsDescription.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'subtitle-instagram',
        title: translate('page.templates.list.subtitleInstagram.title'),
        description: translate(
          'page.templates.list.subtitleInstagram.description'
        ),
        type: [options.marketing, options.socialMedia],
        redirect: 'subtitle-instagram',
        imageUrl: baseURL + 'instagram.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.subtitleInstagram.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.subtitleInstagram.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.subtitleInstagram.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.subtitleInstagram.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'blog-post-intro',
        title: translate('page.templates.list.blogPostIntro.title'),
        description: translate('page.templates.list.blogPostIntro.description'),
        type: [options.blog, options.seo],
        redirect: 'blog-post',
        imageUrl: baseURL + 'pencil-edit.svg',
        fields: {
          title: {
            label: translate(
              'page.templates.list.blogPostIntro.fields.title.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostIntro.fields.title.placeholder'
            ),
            required: true
          },
          audience: {
            label: translate(
              'page.templates.list.blogPostIntro.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostIntro.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.blogPostIntro.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostIntro.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'blog-post-conclusion',
        title: translate('page.templates.list.blogPostConclusion.title'),
        description: translate(
          'page.templates.list.blogPostConclusion.description'
        ),
        type: [options.blog, options.seo],
        redirect: 'blog-post-conclusion',
        imageUrl: baseURL + 'pencil-edit.svg',
        fields: {
          title: {
            label: translate(
              'page.templates.list.blogPostConclusion.fields.title.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostConclusion.fields.title.placeholder'
            ),
            required: true
          },
          audience: {
            label: translate(
              'page.templates.list.blogPostConclusion.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostConclusion.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.blogPostConclusion.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostConclusion.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'blog-post-outline',
        title: translate('page.templates.list.blogPostOutline.title'),
        description: translate(
          'page.templates.list.blogPostOutline.description'
        ),
        type: [options.blog, options.seo],
        redirect: 'blog-post-outline',
        imageUrl: baseURL + 'pencil-edit.svg',
        fields: {
          title: {
            label: translate(
              'page.templates.list.blogPostOutline.fields.title.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostOutline.fields.title.placeholder'
            ),
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.blogPostOutline.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostOutline.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'blog-post-topic-ideas',
        title: translate('page.templates.list.blogPostTopicIdeas.title'),
        description: translate(
          'page.templates.list.blogPostTopicIdeas.description'
        ),
        type: [options.blog, options.seo],
        redirect: 'blog-post-topic-ideas',
        imageUrl: baseURL + 'pencil-edit.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.blogPostTopicIdeas.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostTopicIdeas.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.blogPostTopicIdeas.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostTopicIdeas.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          audience: {
            label: translate(
              'page.templates.list.blogPostTopicIdeas.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostTopicIdeas.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.blogPostTopicIdeas.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.blogPostTopicIdeas.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'commands',
        title: translate('page.templates.list.commands.title'),
        description: translate('page.templates.list.commands.description'),
        type: [options.tools],
        redirect: 'commands',
        imageUrl: baseURL + 'command.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.commands.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.commands.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          content: {
            label: translate(
              'page.templates.list.commands.fields.content.label'
            ),
            placeholder: translate(
              'page.templates.list.commands.fields.content.placeholder'
            ),
            rows: 4
          }
        }
      },
      {
        id: 'company-bio',
        title: translate('page.templates.list.companyBio.title'),
        description: translate('page.templates.list.companyBio.description'),
        type: [options.ecommerce, options.marketing],
        redirect: 'company-bio',
        imageUrl: baseURL + 'company-bio.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.companyBio.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.companyBio.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.companyBio.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.companyBio.fields.description.placeholder'
            ),
            rows: 4
          },
          audience: {
            label: translate(
              'page.templates.list.companyBio.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.companyBio.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.companyBio.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.companyBio.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'content-improver',
        title: translate('page.templates.list.contentImprover.title'),
        description: translate(
          'page.templates.list.contentImprover.description'
        ),
        type: [options.marketing, options.socialMedia],
        redirect: 'content-improver',
        imageUrl: baseURL + 'content-improver.svg',
        fields: {
          content: {
            label: translate(
              'page.templates.list.contentImprover.fields.content.label'
            ),
            placeholder: translate(
              'page.templates.list.contentImprover.fields.content.placeholder'
            ),
            rows: 6,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.contentImprover.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.contentImprover.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'rewrite-text',
        title: translate('page.templates.list.rewriteText.title'),
        description: translate('page.templates.list.rewriteText.description'),
        type: [options.marketing, options.socialMedia],
        redirect: 'rewrite-text',
        imageUrl: baseURL + 'content-improver.svg',
        fields: {
          content: {
            label: translate(
              'page.templates.list.rewriteText.fields.content.label'
            ),
            placeholder: translate(
              'page.templates.list.rewriteText.fields.content.placeholder'
            ),
            rows: platform === 'standard' ? 6 : 12,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.rewriteText.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.rewriteText.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'email-subject',
        title: translate('page.templates.list.emailSubject.title'),
        description: translate('page.templates.list.emailSubject.description'),
        type: [options.email],
        redirect: 'email-subject',
        imageUrl: baseURL + 'emails.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.emailSubject.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.emailSubject.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.emailSubject.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.emailSubject.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.emailSubject.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.emailSubject.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'email-marketing',
        title: translate('page.templates.list.emailMarketing.title'),
        description: translate(
          'page.templates.list.emailMarketing.description'
        ),
        type: [options.email, options.marketing],
        redirect: 'email-marketing',
        imageUrl: baseURL + 'emails.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.emailMarketing.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.emailMarketing.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.emailMarketing.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.emailMarketing.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          cta: {
            label: translate(
              'page.templates.list.emailMarketing.fields.cta.label'
            ),
            placeholder: translate(
              'page.templates.list.emailMarketing.fields.cta.placeholder'
            ),
            required: true
          }
        }
      },
      {
        id: 'paragraphs-generator',
        title: translate('page.templates.list.paragraphsGenerator.title'),
        description: translate(
          'page.templates.list.paragraphsGenerator.description'
        ),
        type: [options.blog],
        redirect: 'paragraphs-generator',
        imageUrl: baseURL + 'notebook-pencil.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.paragraphsGenerator.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.paragraphsGenerator.fields.description.placeholder'
            ),
            required: true
          },
          keywords: {
            label: translate(
              'page.templates.list.paragraphsGenerator.fields.keywords.label'
            ),
            placeholder: translate(
              'page.templates.list.paragraphsGenerator.fields.keywords.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.paragraphsGenerator.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.paragraphsGenerator.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'product-description',
        title: translate('page.templates.list.productDescription.title'),
        description: translate(
          'page.templates.list.productDescription.description'
        ),
        type: [options.ecommerce, options.marketing],
        redirect: 'product-description',
        imageUrl: baseURL + 'target.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.productDescription.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.productDescription.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.productDescription.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.productDescription.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          audience: {
            label: translate(
              'page.templates.list.productDescription.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.productDescription.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.productDescription.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.productDescription.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'subtitle-tiktok',
        title: translate('page.templates.list.subtitleTikTok.title'),
        description: translate(
          'page.templates.list.subtitleTikTok.description'
        ),
        type: [options.marketing, options.socialMedia, options.videos],
        redirect: 'subtitle-tiktok',
        imageUrl: baseURL + 'tiktok.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.subtitleTikTok.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.subtitleTikTok.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.subtitleTikTok.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.subtitleTikTok.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'carousel-instagram',
        title: translate('page.templates.list.carouselInstagram.title'),
        description: translate(
          'page.templates.list.carouselInstagram.description'
        ),
        type: [options.marketing, options.socialMedia],
        redirect: 'carousel-instagram',
        imageUrl: baseURL + 'instagram.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.carouselInstagram.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.carouselInstagram.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.carouselInstagram.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.carouselInstagram.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'title-youtube',
        title: translate('page.templates.list.titleYouTube.title'),
        description: translate('page.templates.list.titleYouTube.description'),
        type: [options.socialMedia, options.videos],
        redirect: 'title-youtube',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.titleYouTube.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.titleYouTube.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.titleYouTube.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.titleYouTube.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'tags-youtube',
        title: translate('page.templates.list.tagsYouTube.title'),
        description: translate('page.templates.list.tagsYouTube.description'),
        type: [options.socialMedia, options.videos],
        redirect: 'tags-youtube',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          title: {
            label: translate(
              'page.templates.list.tagsYouTube.fields.title.label'
            ),
            placeholder: translate(
              'page.templates.list.tagsYouTube.fields.title.placeholder'
            ),
            rows: 1,
            required: true
          }
        }
      },
      {
        id: 'youtube-topic-ideas',
        title: translate('page.templates.list.youtubeTopicIdeas.title'),
        description: translate(
          'page.templates.list.youtubeTopicIdeas.description'
        ),
        type: [options.socialMedia, options.videos],
        redirect: 'youtube-topic-ideas',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.youtubeTopicIdeas.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.youtubeTopicIdeas.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          audience: {
            label: translate(
              'page.templates.list.youtubeTopicIdeas.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.youtubeTopicIdeas.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.youtubeTopicIdeas.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.youtubeTopicIdeas.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'description-youtube',
        title: translate('page.templates.list.descriptionYouTube.title'),
        description: translate(
          'page.templates.list.descriptionYouTube.description'
        ),
        type: [options.socialMedia, options.videos],
        redirect: 'description-youtube',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          title: {
            label: translate(
              'page.templates.list.descriptionYouTube.fields.title.label'
            ),
            placeholder: translate(
              'page.templates.list.descriptionYouTube.fields.title.placeholder'
            ),
            rows: 1,
            required: true
          }
        }
      },
      {
        id: 'youtube-outline',
        title: translate('page.templates.list.youtubeOutline.title'),
        description: translate(
          'page.templates.list.youtubeOutline.description'
        ),
        type: [options.socialMedia, options.videos],
        redirect: 'youtube-outline',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          title: {
            label: translate(
              'page.templates.list.youtubeOutline.fields.title.label'
            ),
            placeholder: translate(
              'page.templates.list.youtubeOutline.fields.title.placeholder'
            ),
            rows: 1,
            required: true
          }
        }
      },
      {
        id: 'youtube-introduction',
        title: translate('page.templates.list.youtubeIntroduction.title'),
        description: translate(
          'page.templates.list.youtubeIntroduction.description'
        ),
        type: [options.socialMedia, options.videos],
        redirect: 'youtube-introduction',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          title: {
            label: translate(
              'page.templates.list.youtubeIntroduction.fields.title.label'
            ),
            placeholder: translate(
              'page.templates.list.youtubeIntroduction.fields.title.placeholder'
            ),
            rows: 1,
            required: true
          }
        }
      },
      {
        id: 'weekly-content-calendar',
        title: translate('page.templates.list.weeklyContentCalendar.title'),
        description: translate(
          'page.templates.list.weeklyContentCalendar.description'
        ),
        type: [options.marketing, options.socialMedia],
        redirect: 'weekly-content-calendar',
        imageUrl: baseURL + 'calendar.svg',
        fields: {
          keywords: {
            label: translate(
              'page.templates.list.weeklyContentCalendar.fields.keywords.label'
            ),
            placeholder: translate(
              'page.templates.list.weeklyContentCalendar.fields.keywords.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.weeklyContentCalendar.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.weeklyContentCalendar.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'commemorative-date',
        title: translate('page.templates.list.commemorativeDate.title'),
        description: translate(
          'page.templates.list.commemorativeDate.description'
        ),
        type: [options.marketing, options.socialMedia, options.tools],
        redirect: 'commemorative-date',
        imageUrl: baseURL + 'champaine.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.commemorativeDate.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.commemorativeDate.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.commemorativeDate.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.commemorativeDate.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'explain-to-a-child',
        title: translate('page.templates.list.explainToAChild.title'),
        description: translate(
          'page.templates.list.explainToAChild.description'
        ),
        type: [options.tools, options.others],
        redirect: 'explain-to-a-child',
        imageUrl: baseURL + 'baby.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.explainToAChild.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.explainToAChild.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          count: {
            label: translate(
              'page.templates.list.explainToAChild.fields.count.label'
            ),
            placeholder: translate(
              'page.templates.list.explainToAChild.fields.count.placeholder'
            ),
            options: [
              {
                value: '1',
                description: translate(
                  'page.templates.list.explainToAChild.fields.count.options.1'
                )
              },
              {
                value: '2',
                description: translate(
                  'page.templates.list.explainToAChild.fields.count.options.2'
                )
              }
            ]
          }
        }
      },
      {
        id: 'domain-name-generator',
        title: translate('page.templates.list.domainNameGenerator.title'),
        description: translate(
          'page.templates.list.domainNameGenerator.description'
        ),
        type: [options.website, options.others],
        redirect: 'domain-name-generator',
        imageUrl: baseURL + 'global.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.domainNameGenerator.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.domainNameGenerator.fields.description.placeholder'
            ),
            rows: 1,
            required: true
          }
        }
      },
      {
        id: 'citation',
        title: translate('page.templates.list.citation.title'),
        description: translate('page.templates.list.citation.description'),
        type: [options.marketing, options.others],
        redirect: 'citation',
        imageUrl: baseURL + 'quote.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.citation.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.citation.fields.description.placeholder'
            ),
            rows: 1,
            required: true
          },
          tone: {
            label: translate('page.templates.list.citation.fields.tone.label'),
            placeholder: translate(
              'page.templates.list.citation.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'company-slogan',
        title: translate('page.templates.list.companySlogan.title'),
        description: translate('page.templates.list.companySlogan.description'),
        type: [options.marketing, options.others],
        redirect: 'company-slogan',
        imageUrl: baseURL + 'light.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.companySlogan.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.companySlogan.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.companySlogan.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.companySlogan.fields.description.placeholder'
            ),
            rows: 4
          },
          tone: {
            label: translate(
              'page.templates.list.companySlogan.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.companySlogan.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'growth-ideas',
        title: translate('page.templates.list.growthIdeas.title'),
        description: translate('page.templates.list.growthIdeas.description'),
        type: [options.ecommerce, options.marketing, options.others],
        redirect: 'growth-ideas',
        imageUrl: baseURL + 'rocket.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.growthIdeas.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.growthIdeas.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.growthIdeas.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.growthIdeas.fields.description.placeholder'
            ),
            rows: 4
          },
          tone: {
            label: translate(
              'page.templates.list.growthIdeas.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.growthIdeas.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'company-mission',
        title: translate('page.templates.list.companyMission.title'),
        description: translate(
          'page.templates.list.companyMission.description'
        ),
        type: [options.marketing, options.others],
        redirect: 'company-mission',
        imageUrl: baseURL + 'company-mission.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.companyMission.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.companyMission.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.companyMission.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.companyMission.fields.description.placeholder'
            ),
            rows: 4
          }
        }
      },
      {
        id: 'company-name',
        title: translate('page.templates.list.companyName.title'),
        description: translate('page.templates.list.companyName.description'),
        type: [options.ecommerce, options.marketing, options.others],
        redirect: 'company-name',
        imageUrl: baseURL + 'energy.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.companyName.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.companyName.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          keywords: {
            label: translate(
              'page.templates.list.companyName.fields.keywords.label'
            ),
            placeholder: translate(
              'page.templates.list.companyName.fields.keywords.placeholder'
            )
          }
        }
      },
      {
        id: 'company-vision',
        title: translate('page.templates.list.companyVision.title'),
        description: translate('page.templates.list.companyVision.description'),
        type: [options.marketing, options.others],
        redirect: 'company-vision',
        imageUrl: baseURL + 'energy.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.companyVision.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.companyVision.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.companyVision.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.companyVision.fields.description.placeholder'
            ),
            rows: 4
          },
          tone: {
            label: translate(
              'page.templates.list.companyVision.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.companyVision.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'facebook-ads-title',
        title: translate('page.templates.list.facebookAdsTitle.title'),
        description: translate(
          'page.templates.list.facebookAdsTitle.description'
        ),
        type: [options.marketing, options.ads],
        redirect: 'facebook-ads-title',
        imageUrl: baseURL + 'facebook.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.facebookAdsTitle.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.facebookAdsTitle.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.facebookAdsTitle.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.facebookAdsTitle.fields.description.placeholder'
            ),
            rows: 4
          },
          audience: {
            label: translate(
              'page.templates.list.facebookAdsTitle.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.facebookAdsTitle.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.facebookAdsTitle.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.facebookAdsTitle.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'seo-homepage',
        title: translate('page.templates.list.seoHomepage.title'),
        description: translate('page.templates.list.seoHomepage.description'),
        type: [options.ecommerce, options.seo, options.website],
        redirect: 'seo-homepage',
        imageUrl: baseURL + 'seo.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.seoHomepage.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.seoHomepage.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.seoHomepage.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.seoHomepage.fields.description.placeholder'
            ),
            rows: 4
          },
          keywords: {
            label: translate(
              'page.templates.list.seoHomepage.fields.keywords.label'
            ),
            placeholder: translate(
              'page.templates.list.seoHomepage.fields.keywords.placeholder'
            )
          }
        }
      },
      {
        id: 'seo-service-page',
        title: translate('page.templates.list.seoServicePage.title'),
        description: translate(
          'page.templates.list.seoServicePage.description'
        ),
        type: [options.ecommerce, options.seo, options.website],
        redirect: 'seo-service-page',
        imageUrl: baseURL + 'seo.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.seoServicePage.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.seoServicePage.fields.name.placeholder'
            ),
            required: true
          },
          theme: {
            label: translate(
              'page.templates.list.seoServicePage.fields.theme.label'
            ),
            placeholder: translate(
              'page.templates.list.seoServicePage.fields.theme.placeholder'
            ),
            rows: 4
          },
          description: {
            label: translate(
              'page.templates.list.seoServicePage.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.seoServicePage.fields.description.placeholder'
            ),
            rows: 4
          },
          keywords: {
            label: translate(
              'page.templates.list.seoServicePage.fields.keywords.label'
            ),
            placeholder: translate(
              'page.templates.list.seoServicePage.fields.keywords.placeholder'
            )
          }
        }
      },
      {
        id: 'seo-product-page',
        title: translate('page.templates.list.seoProductPage.title'),
        description: translate(
          'page.templates.list.seoProductPage.description'
        ),
        type: [options.ecommerce, options.seo, options.website],
        redirect: 'seo-product-page',
        imageUrl: baseURL + 'seo.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.seoProductPage.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.seoProductPage.fields.name.placeholder'
            ),
            required: true
          },
          theme: {
            label: translate(
              'page.templates.list.seoProductPage.fields.theme.label'
            ),
            placeholder: translate(
              'page.templates.list.seoProductPage.fields.theme.placeholder'
            ),
            rows: 4,
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.seoProductPage.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.seoProductPage.fields.description.placeholder'
            ),
            rows: 4
          },
          keywords: {
            label: translate(
              'page.templates.list.seoProductPage.fields.keywords.label'
            ),
            placeholder: translate(
              'page.templates.list.seoProductPage.fields.keywords.placeholder'
            )
          }
        }
      },
      {
        id: 'seo-blog-post',
        title: translate('page.templates.list.seoBlogPost.title'),
        description: translate('page.templates.list.seoBlogPost.description'),
        type: [options.blog, options.seo, options.website],
        redirect: 'seo-blog-post',
        imageUrl: baseURL + 'seo.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.seoBlogPost.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.seoBlogPost.fields.name.placeholder'
            ),
            required: true
          },
          title: {
            label: translate(
              'page.templates.list.seoBlogPost.fields.title.label'
            ),
            placeholder: translate(
              'page.templates.list.seoBlogPost.fields.title.placeholder'
            ),
            rows: 4,
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.seoBlogPost.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.seoBlogPost.fields.description.placeholder'
            ),
            rows: 4
          }
        }
      },
      {
        id: 'linkedin-ads-title',
        title: translate('page.templates.list.linkedinAdsTitle.title'),
        description: translate(
          'page.templates.list.linkedinAdsTitle.description'
        ),
        type: [options.ads, options.marketing],
        redirect: 'linkedin-ads-title',
        imageUrl: baseURL + 'linkedin.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.linkedinAdsTitle.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinAdsTitle.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.linkedinAdsTitle.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinAdsTitle.fields.description.placeholder'
            ),
            rows: 4
          },
          audience: {
            label: translate(
              'page.templates.list.linkedinAdsTitle.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinAdsTitle.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.linkedinAdsTitle.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinAdsTitle.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'linkedin-ads-description',
        title: translate('page.templates.list.linkedinAdsDescription.title'),
        description: translate(
          'page.templates.list.linkedinAdsDescription.description'
        ),
        type: [options.ads, options.marketing],
        redirect: 'linkedin-ads-description',
        imageUrl: baseURL + 'linkedin.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.linkedinAdsDescription.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinAdsDescription.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.linkedinAdsDescription.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinAdsDescription.fields.description.placeholder'
            ),
            rows: 4
          },
          audience: {
            label: translate(
              'page.templates.list.linkedinAdsDescription.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinAdsDescription.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.linkedinAdsDescription.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinAdsDescription.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'linkedin-post',
        title: translate('page.templates.list.linkedinPost.title'),
        description: translate('page.templates.list.linkedinPost.description'),
        type: [options.ads, options.socialMedia, options.others],
        redirect: 'linkedin-post',
        imageUrl: baseURL + 'linkedin.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.linkedinPost.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinPost.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          audience: {
            label: translate(
              'page.templates.list.linkedinPost.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinPost.fields.audience.placeholder'
            )
          },
          cta: {
            label: translate(
              'page.templates.list.linkedinPost.fields.cta.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinPost.fields.cta.placeholder'
            )
          }
        }
      },
      {
        id: 'twitter-post',
        title: translate('page.templates.list.twitterPost.title'),
        description: translate('page.templates.list.twitterPost.description'),
        type: [options.marketing, options.socialMedia],
        redirect: 'twitter-post',
        imageUrl: baseURL + 'twitter.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.twitterPost.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.twitterPost.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.twitterPost.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.twitterPost.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'google-ads-title',
        title: translate('page.templates.list.googleAdsTitle.title'),
        description: translate(
          'page.templates.list.googleAdsTitle.description'
        ),
        type: [options.ads, options.google, options.marketing],
        redirect: 'google-ads-title',
        imageUrl: baseURL + 'google.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.googleAdsTitle.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.googleAdsTitle.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.googleAdsTitle.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.googleAdsTitle.fields.description.placeholder'
            ),
            rows: 4
          },
          audience: {
            label: translate(
              'page.templates.list.googleAdsTitle.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.googleAdsTitle.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.googleAdsTitle.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.googleAdsTitle.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'google-ads-description',
        title: translate('page.templates.list.googleAdsDescription.title'),
        description: translate(
          'page.templates.list.googleAdsDescription.description'
        ),
        type: [options.ads, options.google, options.marketing],
        redirect: 'google-ads-description',
        imageUrl: baseURL + 'google.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.googleAdsDescription.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.googleAdsDescription.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.googleAdsDescription.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.googleAdsDescription.fields.description.placeholder'
            ),
            rows: 4
          },
          audience: {
            label: translate(
              'page.templates.list.googleAdsDescription.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.googleAdsDescription.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.googleAdsDescription.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.googleAdsDescription.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'resources-for-benefits',
        title: translate('page.templates.list.resourcesForBenefits.title'),
        description: translate(
          'page.templates.list.resourcesForBenefits.description'
        ),
        type: [options.frameworks, options.marketing],
        redirect: 'resources-for-benefits',
        imageUrl: baseURL + 'cup.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.resourcesForBenefits.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.resourcesForBenefits.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          }
        }
      },
      {
        id: 'image-prompt',
        title: translate('page.templates.list.imagePrompt.title'),
        description: translate('page.templates.list.imagePrompt.description'),
        type: [options.tools, options.others],
        redirect: 'image-prompt',
        imageUrl: baseURL + 'picture.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.imagePrompt.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.imagePrompt.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.imagePrompt.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.imagePrompt.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'personal-biography',
        title: translate('page.templates.list.personalBiography.title'),
        description: translate(
          'page.templates.list.personalBiography.description'
        ),
        type: [options.tools, options.others],
        redirect: 'personal-biography',
        imageUrl: baseURL + 'user.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.personalBiography.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.personalBiography.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.personalBiography.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.personalBiography.fields.description.placeholder'
            ),
            rows: 14
          },
          pointsOfView: {
            label: translate(
              'page.templates.list.personalBiography.fields.pointsOfView.label'
            ),
            placeholder: translate(
              'page.templates.list.personalBiography.fields.pointsOfView.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.personalBiography.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.personalBiography.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'app-notifications',
        title: translate('page.templates.list.appNotifications.title'),
        description: translate(
          'page.templates.list.appNotifications.description'
        ),
        type: [options.tools, options.others],
        redirect: 'app-notifications',
        imageUrl: baseURL + 'notification.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.appNotifications.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.appNotifications.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.appNotifications.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.appNotifications.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'push-notification',
        title: translate('page.templates.list.pushNotification.title'),
        description: translate(
          'page.templates.list.pushNotification.description'
        ),
        type: [options.tools, options.others],
        redirect: 'push-notification',
        imageUrl: baseURL + 'notification.svg',
        fields: {
          title: {
            label: translate(
              'page.templates.list.pushNotification.fields.title.label'
            ),
            placeholder: translate(
              'page.templates.list.pushNotification.fields.title.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.pushNotification.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.pushNotification.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          }
        }
      },
      {
        id: 'aida-structure',
        title: translate('page.templates.list.aidaStructure.title'),
        description: translate('page.templates.list.aidaStructure.description'),
        type: [options.frameworks, options.others],
        redirect: 'aida-structure',
        imageUrl: baseURL + 'aida.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.aidaStructure.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.aidaStructure.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.aidaStructure.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.aidaStructure.fields.description.placeholder'
            ),
            rows: 4
          },
          tone: {
            label: translate(
              'page.templates.list.aidaStructure.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.aidaStructure.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'pas-structure',
        title: translate('page.templates.list.pasStructure.title'),
        description: translate('page.templates.list.pasStructure.description'),
        type: [options.frameworks, options.others],
        redirect: 'pas-structure',
        imageUrl: baseURL + 'aida.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.pasStructure.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.pasStructure.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.pasStructure.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.pasStructure.fields.description.placeholder'
            ),
            rows: 4
          },
          tone: {
            label: translate(
              'page.templates.list.pasStructure.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.pasStructure.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'faq-generator',
        title: translate('page.templates.list.faqGenerator.title'),
        description: translate('page.templates.list.faqGenerator.description'),
        type: [options.tools, options.others],
        redirect: 'faq-generator',
        imageUrl: baseURL + 'question.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.faqGenerator.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.faqGenerator.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.faqGenerator.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.faqGenerator.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'engaging-questions',
        title: translate('page.templates.list.engagingQuestions.title'),
        description: translate(
          'page.templates.list.engagingQuestions.description'
        ),
        type: [options.tools, options.others],
        redirect: 'engaging-questions',
        imageUrl: baseURL + 'question.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.engagingQuestions.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.engagingQuestions.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          audience: {
            label: translate(
              'page.templates.list.engagingQuestions.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.engagingQuestions.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.engagingQuestions.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.engagingQuestions.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'job-interview',
        title: translate('page.templates.list.jobInterview.title'),
        description: translate('page.templates.list.jobInterview.description'),
        type: [options.tools, options.others],
        redirect: 'job-interview',
        imageUrl: baseURL + 'handshake.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.jobInterview.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.jobInterview.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.jobInterview.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.jobInterview.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'class-plan',
        title: translate('page.templates.list.classPlan.title'),
        description: translate('page.templates.list.classPlan.description'),
        type: [options.tools, options.others],
        redirect: 'class-plan',
        imageUrl: baseURL + 'pencil.svg',
        fields: {
          content: {
            label: translate(
              'page.templates.list.classPlan.fields.content.label'
            ),
            placeholder: translate(
              'page.templates.list.classPlan.fields.content.placeholder'
            ),
            rows: 4,
            required: true
          },
          audience: {
            label: translate(
              'page.templates.list.classPlan.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.classPlan.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate('page.templates.list.classPlan.fields.tone.label'),
            placeholder: translate(
              'page.templates.list.classPlan.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'perfect-title',
        title: translate('page.templates.list.perfectTitle.title'),
        description: translate('page.templates.list.perfectTitle.description'),
        type: [options.tools, options.others],
        redirect: 'perfect-title',
        imageUrl: baseURL + 'perfect-title.svg',
        fields: {
          theme: {
            label: translate(
              'page.templates.list.perfectTitle.fields.theme.label'
            ),
            placeholder: translate(
              'page.templates.list.perfectTitle.fields.theme.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.perfectTitle.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.perfectTitle.fields.description.placeholder'
            ),
            rows: 4
          },
          audience: {
            label: translate(
              'page.templates.list.perfectTitle.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.perfectTitle.fields.audience.placeholder'
            )
          }
        }
      },
      {
        id: 'custom-emails',
        title: translate('page.templates.list.customEmails.title'),
        description: translate('page.templates.list.customEmails.description'),
        type: [options.email],
        redirect: 'custom-emails',
        imageUrl: baseURL + 'emails.svg',
        fields: {
          name: {
            label: translate(
              'page.templates.list.customEmails.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.customEmails.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.customEmails.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.customEmails.fields.description.placeholder'
            ),
            rows: 4
          },
          content: {
            label: translate(
              'page.templates.list.customEmails.fields.content.label'
            ),
            placeholder: translate(
              'page.templates.list.customEmails.fields.content.placeholder'
            ),
            rows: 2
          },
          cta: {
            label: translate(
              'page.templates.list.customEmails.fields.cta.label'
            ),
            placeholder: translate(
              'page.templates.list.customEmails.fields.cta.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.customEmails.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.customEmails.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'google-my-business',
        title: translate('page.templates.list.googleMyBusiness.title'),
        description: translate(
          'page.templates.list.googleMyBusiness.description'
        ),
        type: [options.ads, options.google],
        redirect: 'google-my-business',
        imageUrl: baseURL + 'google.svg',
        fields: {
          location: {
            label: translate(
              'page.templates.list.googleMyBusiness.fields.location.label'
            ),
            placeholder: translate(
              'page.templates.list.googleMyBusiness.fields.location.placeholder'
            ),
            required: true
          },
          name: {
            label: translate(
              'page.templates.list.googleMyBusiness.fields.name.label'
            ),
            placeholder: translate(
              'page.templates.list.googleMyBusiness.fields.name.placeholder'
            )
          },
          description: {
            label: translate(
              'page.templates.list.googleMyBusiness.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.googleMyBusiness.fields.description.placeholder'
            ),
            rows: 4
          }
        }
      },
      {
        id: 'list-creator',
        title: translate('page.templates.list.listCreator.title'),
        description: translate('page.templates.list.listCreator.description'),
        type: [options.tools, options.others],
        redirect: 'list-creator',
        imageUrl: baseURL + 'list.svg',
        fields: {
          theme: {
            label: translate(
              'page.templates.list.listCreator.fields.theme.label'
            ),
            placeholder: translate(
              'page.templates.list.listCreator.fields.theme.placeholder'
            ),
            required: true
          },
          count: {
            label: translate(
              'page.templates.list.listCreator.fields.count.label'
            ),
            placeholder: translate(
              'page.templates.list.listCreator.fields.count.placeholder'
            ),
            options: [
              { value: 1, description: '1' },
              { value: 2, description: '2' },
              { value: 3, description: '3' },
              { value: 4, description: '4' },
              { value: 5, description: '5' },
              { value: 6, description: '6' },
              { value: 7, description: '7' },
              { value: 8, description: '8' },
              { value: 9, description: '9' },
              { value: 10, description: '10' }
            ]
          },
          tone: {
            label: translate(
              'page.templates.list.listCreator.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.listCreator.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'analogy-creator',
        title: translate('page.templates.list.analogyCreator.title'),
        description: translate(
          'page.templates.list.analogyCreator.description'
        ),
        type: [options.tools, options.others],
        redirect: 'analogy-creator',
        imageUrl: baseURL + 'happy.svg',
        fields: {
          theme: {
            label: translate(
              'page.templates.list.analogyCreator.fields.theme.label'
            ),
            placeholder: translate(
              'page.templates.list.analogyCreator.fields.theme.placeholder'
            ),
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.analogyCreator.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.analogyCreator.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'content-expander',
        title: translate('page.templates.list.contentExpander.title'),
        description: translate(
          'page.templates.list.contentExpander.description'
        ),
        type: [options.tools, options.others],
        redirect: 'content-expander',
        imageUrl: baseURL + 'ia.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.contentExpander.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.contentExpander.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          count: {
            label: translate(
              'page.templates.list.contentExpander.fields.count.label'
            ),
            placeholder: translate(
              'page.templates.list.contentExpander.fields.count.placeholder'
            ),
            options: [
              { value: '50', description: '50 palavras' },
              { value: '100', description: '100 palavras' },
              { value: '200', description: '200 palavras' },
              { value: '300', description: '300 palavras' },
              { value: '400', description: '400 palavras' }
            ]
          },
          tone: {
            label: translate(
              'page.templates.list.contentExpander.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.contentExpander.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'smart-answers',
        title: translate('page.templates.list.smartAnswers.title'),
        description: translate('page.templates.list.smartAnswers.description'),
        type: [options.tools, options.others],
        redirect: 'smart-answers',
        imageUrl: baseURL + 'ia.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.smartAnswers.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.smartAnswers.fields.description.placeholder'
            ),
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.smartAnswers.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.smartAnswers.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'creative-story',
        title: translate('page.templates.list.creativeStory.title'),
        description: translate('page.templates.list.creativeStory.description'),
        type: [options.tools, options.others],
        redirect: 'creative-story',
        imageUrl: baseURL + 'camera.svg',
        fields: {
          theme: {
            label: translate(
              'page.templates.list.creativeStory.fields.theme.label'
            ),
            placeholder: translate(
              'page.templates.list.creativeStory.fields.theme.placeholder'
            ),
            required: true
          },
          tone: {
            label: translate(
              'page.templates.list.creativeStory.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.creativeStory.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'unique-value-propositions',
        title: translate('page.templates.list.uniqueValuePropositions.title'),
        description: translate(
          'page.templates.list.uniqueValuePropositions.description'
        ),
        type: [options.ecommerce, options.marketing, options.others],
        redirect: 'unique-value-propositions',
        imageUrl: baseURL + 'content-improver.svg',
        fields: {
          theme: {
            label: translate(
              'page.templates.list.uniqueValuePropositions.fields.theme.label'
            ),
            placeholder: translate(
              'page.templates.list.uniqueValuePropositions.fields.theme.placeholder'
            ),
            required: true
          },
          audience: {
            label: translate(
              'page.templates.list.uniqueValuePropositions.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.uniqueValuePropositions.fields.audience.placeholder'
            )
          },
          description: {
            label: translate(
              'page.templates.list.uniqueValuePropositions.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.uniqueValuePropositions.fields.description.placeholder'
            )
          },
          content: {
            label: translate(
              'page.templates.list.uniqueValuePropositions.fields.content.label'
            ),
            placeholder: translate(
              'page.templates.list.uniqueValuePropositions.fields.content.placeholder'
            )
          }
        }
      },
      {
        id: 'linkedin-topic-ideas',
        title: translate('page.templates.list.linkedinTopicIdeas.title'),
        description: translate(
          'page.templates.list.linkedinTopicIdeas.description'
        ),
        type: [options.socialMedia],
        redirect: 'linkedin-topic-ideas',
        imageUrl: baseURL + 'linkedin.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.linkedinTopicIdeas.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinTopicIdeas.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          count: {
            label: translate(
              'page.templates.list.linkedinTopicIdeas.fields.count.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinTopicIdeas.fields.count.placeholder'
            ),
            options: [
              { value: 1, description: '1' },
              { value: 2, description: '2' },
              { value: 3, description: '3' },
              { value: 4, description: '4' },
              { value: 5, description: '5' },
              { value: 6, description: '6' },
              { value: 7, description: '7' },
              { value: 8, description: '8' },
              { value: 9, description: '9' },
              { value: 10, description: '10' }
            ]
          },
          audience: {
            label: translate(
              'page.templates.list.linkedinTopicIdeas.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinTopicIdeas.fields.audience.placeholder'
            )
          },
          cta: {
            label: translate(
              'page.templates.list.linkedinTopicIdeas.fields.cta.label'
            ),
            placeholder: translate(
              'page.templates.list.linkedinTopicIdeas.fields.cta.placeholder'
            )
          }
        }
      },
      {
        id: 'instagram-topic-ideas',
        title: translate('page.templates.list.instagramTopicIdeas.title'),
        description: translate(
          'page.templates.list.instagramTopicIdeas.description'
        ),
        type: [options.socialMedia],
        redirect: 'instagram-topic-ideas',
        imageUrl: baseURL + 'instagram.svg',
        fields: {
          description: {
            label: translate(
              'page.templates.list.instagramTopicIdeas.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.instagramTopicIdeas.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          count: {
            label: translate(
              'page.templates.list.instagramTopicIdeas.fields.count.label'
            ),
            placeholder: translate(
              'page.templates.list.instagramTopicIdeas.fields.count.placeholder'
            ),
            options: [
              { value: 1, description: '1' },
              { value: 2, description: '2' },
              { value: 3, description: '3' },
              { value: 4, description: '4' },
              { value: 5, description: '5' },
              { value: 6, description: '6' },
              { value: 7, description: '7' },
              { value: 8, description: '8' },
              { value: 9, description: '9' },
              { value: 10, description: '10' }
            ],
            required: true
          },
          audience: {
            label: translate(
              'page.templates.list.instagramTopicIdeas.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.instagramTopicIdeas.fields.audience.placeholder'
            )
          },
          cta: {
            label: translate(
              'page.templates.list.instagramTopicIdeas.fields.cta.label'
            ),
            placeholder: translate(
              'page.templates.list.instagramTopicIdeas.fields.cta.placeholder'
            )
          }
        }
      },
      {
        id: 'instagram-ads-description',
        title: translate('page.templates.list.instagramAdsDescription.title'),
        description: translate(
          'page.templates.list.instagramAdsDescription.description'
        ),
        type: [options.socialMedia, options.ads, options.marketing],
        redirect: 'instagram-ads-description',
        imageUrl: baseURL + 'instagram.svg',
        fields: {
          theme: {
            label: translate(
              'page.templates.list.instagramAdsDescription.fields.theme.label'
            ),
            placeholder: translate(
              'page.templates.list.instagramAdsDescription.fields.theme.placeholder'
            )
          },
          description: {
            label: translate(
              'page.templates.list.instagramAdsDescription.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.instagramAdsDescription.fields.description.placeholder'
            ),
            rows: 4,
            required: true
          },
          audience: {
            label: translate(
              'page.templates.list.instagramAdsDescription.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.instagramAdsDescription.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate(
              'page.templates.list.instagramAdsDescription.fields.tone.label'
            ),
            placeholder: translate(
              'page.templates.list.instagramAdsDescription.fields.tone.placeholder'
            )
          }
        }
      },
      {
        id: 'ads-ideas',
        title: translate('page.templates.list.adsIdeas.title'),
        description: translate('page.templates.list.adsIdeas.description'),
        type: [options.socialMedia, options.ads, options.marketing],
        redirect: 'ads-ideas',
        imageUrl: baseURL + 'rocket.svg',
        fields: {
          name: {
            label: translate('page.templates.list.adsIdeas.fields.name.label'),
            placeholder: translate(
              'page.templates.list.adsIdeas.fields.name.placeholder'
            ),
            required: true
          },
          description: {
            label: translate(
              'page.templates.list.adsIdeas.fields.description.label'
            ),
            placeholder: translate(
              'page.templates.list.adsIdeas.fields.description.placeholder'
            ),
            rows: 4
          },
          audience: {
            label: translate(
              'page.templates.list.adsIdeas.fields.audience.label'
            ),
            placeholder: translate(
              'page.templates.list.adsIdeas.fields.audience.placeholder'
            )
          },
          tone: {
            label: translate('page.templates.list.adsIdeas.fields.tone.label'),
            placeholder: translate(
              'page.templates.list.adsIdeas.fields.tone.placeholder'
            )
          }
        }
      }
    ]
  };
};
