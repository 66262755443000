import React, { useState } from 'react';
import { PasswordRecoveryFormInterface } from './interface';
import useStyles from './style';
import HeadForm from '../../../../components/HeadForm';
import TitleForm from '../../../../components/TitleForm';
import ButtonSubmit from '../../../../components/ButtonSubmit';
import Input from '../../../../components/Input';
import ChannelsContainer from '../../../../components/ChannelsContainer';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import { getLanguage } from '../../../../../../utils/function/getLanguage';

const PasswordRecoveryForm = ({
  password,
  setPassword,
  loading,
  onSubmit
}: PasswordRecoveryFormInterface) => {
  const classes: any = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const [language, setLanguage] = useState(getLanguage());

  return (
    <div className={classes.root}>
      <div>
        <HeadForm hasButtonBack />

        <TitleForm
          title={translate('page.passwordRecovery.form.title')}
          description={translate('page.passwordRecovery.form.description')}
        />

        <form action="" onSubmit={onSubmit} className={classes.form}>
          <Input
            id="email"
            label={translate('page.passwordRecovery.form.password.label')}
            placeholder={translate(
              'page.passwordRecovery.form.password.placeholder'
            )}
            type="password"
            value={password}
            setValue={setPassword}
          />

          <ButtonSubmit disabled={loading} loading={loading}>
            {translate('page.passwordRecovery.form.action.updatePassword')}
          </ButtonSubmit>

          <ChannelsContainer />
        </form>
      </div>
    </div>
  );
};

export default PasswordRecoveryForm;
